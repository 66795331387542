enum Colors {
  white = 'white',
  black = 'black',
  gray = 'gray',
  lightGray = '#5B5B5B',
  lightBlue = '#f7f7f7',
  policeBlue="#1b2530",
  policeGray= "#94949e",
  Merino = '#fcfbf6',
  Mirage = '#1B2530',
  Geyser = '#C7D4DA',
}

export default Colors;
