import React from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";

function DomesticPageContacts() {
  return (
    <div
      className={classNames(
        css(styles.normalFont),
        "d-flex flex-row border border-dark border-3"
      )}
    >
      <div className="d-flex col-4 border-end border-dark">
        NYS DIVISION OF CRIMINAL JUSTICE SERVICES COPY
      </div>
      <div className="d-flex col-4 border-end border-dark">
        NYS DOMESTIC AND SEXUAL VIOLENCE HOTLINE 1-800-942-6906
      </div>
      <div className="d-flex col-4">
        3221-02/2020 DCJS Copyright © 2020 by NYS DCJS
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.7rem",
  },
});

export default DomesticPageContacts;
