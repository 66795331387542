import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Col, Container, Form, Row, Spinner, InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import { If, For } from 'tsx-control-statements/components';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { AuthenticationRoutes } from 'constants/generalRoutes';
import AWSCognitoEmailVerification from 'components/auth/Registration/AWSCognitoEmailVerification';
import { genderOptions, roleOptions } from 'components/auth/Registration/FormOptions';
import { REGISTERED } from 'constants/cookies';
import useAuth from 'hooks/useAuth';
import useCookie from 'hooks/useCookie';

const AuthRegister: React.FC = () => {
  const { setCookie, getCookie } = useCookie();
  const { register } = useAuth();
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(getCookie(REGISTERED) === 'true');
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      gender: '',
      password: '',
      confirmPassword: '',
      contactNumber: '',
      role: '',
      cop_id: '',
      signup_response: ''
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('First Name is required'),
      lastname: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      gender: Yup.string().required('Gender is required'),
      password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
      contactNumber: Yup.string(),
      role: Yup.string().required('Role is required'),
      cop_id: Yup.string().required('Cop ID is required')
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setLoading(true);
      try {
        const res = await register(
          values.email,
          values.password,
          values.firstname,
          values.lastname,
          values.gender,
          values.contactNumber,
          values.role,
          values.cop_id
        );
        if (res) {
          setCookie(REGISTERED, 'true');
          setRegistered(true);
        } else {
          throw new Error('Registration failed');
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          setErrors({ signup_response: err.message });
        }
        setCookie(REGISTERED, 'false');
        setRegistered(false);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    }
  });

  const renderFormControl = (
    name: keyof typeof formik.values,
    type: string,
    placeholder: string,
    showToggle?: boolean
  ) => (
    <Form.Group controlId={name} className="mb-3">
      <InputGroup>
        <Form.Control
          type={showToggle && showPassword ? 'text' : type}
          placeholder={placeholder}
          {...formik.getFieldProps(name)}
          isInvalid={formik.touched[name] && !!formik.errors[name]}
        />
        <If condition={showToggle}>
          <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </InputGroup.Text>
        </If>
        <Form.Control.Feedback type="invalid">
          {formik.errors[name]}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );

  const renderSelectControl = (
    name: keyof typeof formik.values,
    options: { value: string; label: string }[],
    placeholder: string
  ) => (
    <Form.Group controlId={name} className="mb-3">
      <Form.Control
        as="select"
        {...formik.getFieldProps(name)}
        isInvalid={formik.touched[name] && !!formik.errors[name]}
      >
        <option value="">{placeholder}</option>
        <For
            of={options}
            body={(option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
            )}
        />
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {formik.errors[name]}
      </Form.Control.Feedback>
    </Form.Group>
  );

  return (
    <Container className={`${css(styles.container)} d-flex align-items-center justify-content-center`}>
      <If condition={registered}>
        <AWSCognitoEmailVerification />
      </If>
      <If condition={!registered}>
        <Form noValidate onSubmit={formik.handleSubmit} className="w-75">
          <Row className="mb-3">
            <Col>{renderFormControl('firstname', 'text', 'First Name')}</Col>
            <Col>{renderFormControl('lastname', 'text', 'Last Name')}</Col>
          </Row>
          {renderSelectControl('gender', genderOptions, 'Select Gender')}
          {renderFormControl('email', 'email', 'Email')}
          {renderFormControl('contactNumber', 'tel', 'Phone Number')}
          {renderSelectControl('role', roleOptions, 'Select Role')}
          {renderFormControl('cop_id', 'text', 'Cop ID')}
          {renderFormControl('password', 'password', 'Password', true)}
          {renderFormControl('confirmPassword', 'password', 'Confirm Password', true)}
          <Button className="w-100" type="submit" disabled={loading}>
            <Choose>
              <When condition={loading}>
                <Spinner animation="border" size="sm" />
              </When>
              <Otherwise>Register</Otherwise>
            </Choose>
          </Button>
          <div className="mt-4 text-center">
            <span>Already Have An Account? </span>
            <Button variant="link" href={AuthenticationRoutes.Login}>
              SignIn here
            </Button>
          </div>
        </Form>
      </If>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: '100vh'
  }
});

export default AuthRegister;
