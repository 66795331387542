import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";
import InputField from "components/domesticTemplate/domesticTemplatePage1/inputField";

import DomesticInputBox from "components/domesticTemplate/domesticTemplatePage1/domesticInputBox";
import useIncidentContext from "lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticPriorHistory() {
  const contentEditableRef = useRef(null);
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [formData, setFormData] = useState({
    agency: "",
    ori: "",
    incidentNumber: "",
    complaintNumber: "",
    priorIncidentsDescription: "",
    localDVServiceProvider: "",
    threatenedToKill: false,
    strangled: false,
    beatenWhilePregnant: false,
    capableOfKilling: false,
    jealous: false,
    increasedViolence: false,
  });
  useEffect(() => {
    if (generatedResult) {
      const history = generatedResult["Prior History"];
      const Incident = generatedResult["Incident"];

      setFormData({
        agency: Incident["agency"],
        ori: Incident["ORI number"],
        incidentNumber: Incident["Incident number"],
        complaintNumber: Incident["complaint number"],
        priorIncidentsDescription: history["priorIncidentsDescription"],
        localDVServiceProvider: Incident[""],
        threatenedToKill: history.threatenedToKill == "Yes",
        strangled: history.strangled == "Yes",
        beatenWhilePregnant: history.beatenWhilePregnant == "Yes",
        capableOfKilling: history.capableOfKilling == "Yes",
        jealous: history.jealous == "Yes",
        increasedViolence: history.increasedViolence == "Yes",
      });
    }
  }, [generatedResult]);

  const handleInputChange = (field: any, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleUserText = (input: any) => {
    setFormData({
      ...formData,
      ["priorIncidentsDescription"]: input,
    });
  };
  const handleInput = (e: any) => {
    handleUserText(e.currentTarget.innerHTML);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    insertTextAtCursor(text);
  };

  const insertTextAtCursor = (text: any) => {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }, [formData.priorIncidentsDescription]);
  return (
    <div className="w-100 p-0 d-flex flex-column flex-fill border border-black border-3">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        Prior History
      </div>
      <div className="m-0 p-0 w-100 d-flex">
        <div className="m-0 p-0 border-start border-black border-3">
          <div className="m-0 p-0 border border-black d-flex flex-row">
            <div className="col-3 border-end border-black">
              <InputField inputFieldText="Agency:" field="agency" defaultValue={formData.agency} userInputHandler={handleInputChange} />
            </div>
            <div className="col-3 fw-bold d-flex p-0">
              <div className="col-2 border-end border-black d-flex align-items-center justify-content-center">
                <h3 className="d-flex">B</h3>
              </div>
              <div className="col-10 border-end border-black d-flex flex-column align-items-center">
                <InputField inputFieldText="ORI: " field="ori" defaultValue={formData.ori} userInputHandler={handleInputChange} />
              </div>
            </div>
            <div className="col-6 d-flex">
              <div className="d-flex border-end border-black flex-fill">
                <InputField
                  inputFieldText="Incident #"
                  field="incidentNumber"
                  defaultValue={formData.incidentNumber}
                  userInputHandler={handleInputChange}
                />
              </div>
              <div className="d-flex flex-fill">
                <InputField
                  inputFieldText="Complaint #"
                  field="complaintNumber"
                  defaultValue={formData.complaintNumber}
                  userInputHandler={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="m-0 p-0 d-flex flex-column border-bottom border-black w-100">
            <div className="d-flex">Describe Victim’s prior domestic incidents with this suspect (Last, Worst, First)</div>

            <div
              className={classNames(css(styles.normalFont, styles.textAreaDiv), "border-0")}
              contentEditable={isEditing}
              ref={contentEditableRef}
              onInput={handleInput}
              onPaste={handlePaste}
              dangerouslySetInnerHTML={{ __html: formData.priorIncidentsDescription }}
            ></div>
          </div>

          <div className={classNames(css(styles.normalFont), "border-end border-bottom border-black d-flex align-content-start flex-wrap p-0")}>
            <div className="d-flex">
              If the Victim answers “yes” to any questions in this box refer to the NYS Domestic and Sexual Violence Hotline at 1-800-942-6906 or
              Local Domestic Violence Service Provider:
            </div>
            <div className="d-flex flex-fill">
              <DomesticInputBox
                defaultValue={formData.localDVServiceProvider}
                userInputHandler={handleInputChange}
                inputFieldText="localDVServiceProvider"
              />
            </div>
          </div>
          <div className="border border-black d-flex flex-row">
            <div className={classNames(css(styles.normalFont), "col-6 border-end border-black d-flex flex-column p-2")}>
              <div className="d-flex pe-1">Has Suspect ever:</div>
              <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0")}>
                <div className="d-flex col-7">Threatened to kill you or your children?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={formData.threatenedToKill == true}
                    onChange={(e) => handleInputChange("threatenedToKill", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={formData.threatenedToKill == false}
                    onChange={(e) => handleInputChange("threatenedToKill", false)}
                    disabled={!isEditing}
                  />
                </div>
              </div>
              <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0")}>
                <div className="d-flex col-7">Strangled or “choked” you?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={formData.strangled == true}
                    onChange={(e) => handleInputChange("strangled", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={formData.strangled == false}
                    onChange={(e) => handleInputChange("strangled", false)}
                    disabled={!isEditing}
                  />
                </div>
              </div>
              <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0")}>
                <div className="d-flex col-7">Beaten you while you were pregnant?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={formData.beatenWhilePregnant == true}
                    onChange={(e) => handleInputChange("beatenWhilePregnant", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={formData.beatenWhilePregnant == false}
                    onChange={(e) => handleInputChange("beatenWhilePregnant", false)}
                    disabled={!isEditing}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(css(styles.normalFont), "col-6 border-end border-black d-flex flex-column p-2")}>
              <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0")}>
                <div className="d-flex col-9">Is suspect capable of killing you or children?</div>
                <div className="d-flex flex-row justify-content-end col-3">
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Yes"
                      className="m-0"
                      checked={formData.capableOfKilling == true}
                      onChange={(e) => handleInputChange("capableOfKilling", true)}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="No"
                      className="m-0"
                      checked={formData.capableOfKilling == false}
                      onChange={(e) => handleInputChange("capableOfKilling", false)}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0")}>
                <div className="d-flex col-9">Is suspect violently and constantly jealous of you?</div>
                <div className="d-flex flex-row justify-content-end col-3">
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Yes"
                      className="m-0"
                      checked={formData.jealous == true}
                      onChange={(e) => handleInputChange("jealous", true)}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="No"
                      className="m-0"
                      checked={formData.jealous == false}
                      onChange={(e) => handleInputChange("jealous", false)}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames(css(styles.normalFont), "d-flex flex-column justify-content-start flex-wrap p-0")}>
                <div className="d-flex">Has the physical violence increased in frequency or severity over the past 6 months?</div>
                <div className="d-flex flex-row justify-content-end">
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Yes"
                      className="m-0"
                      checked={formData.increasedViolence == true}
                      onChange={(e) => handleInputChange("increasedViolence", true)}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="No"
                      className="m-0"
                      checked={formData.increasedViolence == false}
                      onChange={(e) => handleInputChange("increasedViolence", false)}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
  textAreaDiv: {
    width: "100%",
    height: "auto",
    minHeight: "7em",
    resize: "none",
    padding: "0.5em",
    overflowY: "auto",
    borderWidth: 0,
  },
});

export default DomesticPriorHistory;
