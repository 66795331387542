// data will retrive from database  
const CASES = [ 
    {
        id: '1',
        title: "Case Title 1",
        victim: "Victim",
        location: "Location",
        reportedDate: "Reported Date",
        dependent: "Dependent",
        addedDate: "Added Date",
        reportedOfficer: "Reported Officer",
        reportedOffice: "Reported Office",
        caseDescription: "Case desctiption",
        audioUrl: "https://www.w3schools.com/TagS/horse.mp3",
        pdfUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    }, {
        id: '2',
        title: "Case Title 2",
        victim: "Victim",
        location: "Location",
        reportedDate: "Reported Date",
        dependent: "Dependent",
        addedDate: "Added Date",
        reportedOfficer: "Reported Officer",
        reportedOffice: "Reported Office",
        caseDescription: "Case desctiption",
        audioUrl: "https://www.w3schools.com/TagS/horse.mp3",
        pdfUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    }, {
        id: '3',
        title: "Case Title 3",
        victim: "Victim",
        location: "Location",
        reportedDate: "Reported Date",
        dependent: "Dependent",
        addedDate: "Added Date",
        reportedOfficer: "Reported Officer",
        reportedOffice: "Reported Office",
        caseDescription: "Case desctiption",
        audioUrl: "https://www.w3schools.com/TagS/horse.mp3",
        pdfUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    }, {
        id: '4',
        title: "Case Title 4",
        victim: "Victim",
        location: "Location",
        reportedDate: "Reported Date",
        dependent: "Dependent",
        addedDate: "Added Date",
        reportedOfficer: "Reported Officer",
        reportedOffice: "Reported Office",
        caseDescription: "Case desctiption",
        audioUrl: "https://www.w3schools.com/TagS/horse.mp3",
        pdfUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    },{
        id: '5',
        title: "Case Title 5",
        victim: "Victim",
        location: "Location",
        reportedDate: "Reported Date",
        dependent: "Dependent",
        addedDate: "Added Date",
        reportedOfficer: "Reported Officer",
        reportedOffice: "Reported Office",
        caseDescription: "Case desctiption",
        audioUrl: "https://www.w3schools.com/TagS/horse.mp3",
        pdfUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    }
];

export default CASES;
