import React from "react";

import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";
import { Route, Routes } from "react-router-dom";

import ResultDisplay from "components/GeneratedResult";
import IncidentContextProvider from "lib/contexts/IncidentContextProvider";
import GeneralRoutes from "constants/generalRoutes";
import InsertCase from "components/InsertCase";
import LoadingPage from "components/LoadingPage";
import MobileSidebar from "components/sidebarComponents/MobileSidebar";
import SelectTemplate from "components/SelectTemplate";
import TopNavBar from "components/layouts/TopNavBar";
import ViewCase from "components/viewComponents/ViewCase";
import useScreenSize from "hooks/useScreenSize";

function PageLayout() {
  const { isMobileScreen } = useScreenSize();
  const styles = getStyles(isMobileScreen());

  return (
    <div
      className={classNames(css(styles.wrapper), "d-flex flex-row bg-light")}
    >
      <MobileSidebar />
      <div className="d-flex flex-column position-relative">
        <TopNavBar />
        <div
          className={classNames(
            css(styles.selectTemplateWrapper),
            "d-flex justify-content-center mt-4"
          )}
        >
          <IncidentContextProvider>
            <Routes>
              <Route path={GeneralRoutes.InsertCase} element={<InsertCase />} />
              <Route
                path={GeneralRoutes.SelectTemplate}
                element={<SelectTemplate />}
              />
              <Route path={GeneralRoutes.Results} element={<ResultDisplay />} />
              <Route path={GeneralRoutes.ViewCase} element={<ViewCase />} />
              <Route
                path={GeneralRoutes.LoadingPage}
                element={<LoadingPage />}
              />
            </Routes>
          </IncidentContextProvider>
        </div>
      </div>
    </div>
  );
}
const getStyles = (isMobileScreen: any) =>
  StyleSheet.create({
    wrapper: {
      minHeight: "100vh",
      height: "100vh",
    },
    sidebarWrapper: {
      width: "20%",
    },
    selectTemplateWrapper: {
      width: "100vw",
      height: "80vh",
    },
  });

export default PageLayout;
