import React, { useState } from 'react';

import * as Yup from 'yup';
import { Alert, Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import OtpInput from 'react18-input-otp';
import { useNavigate } from 'react-router';

import { AuthenticationRoutes } from 'constants/generalRoutes';
import { REGISTERED } from 'constants/cookies';
import useAuth from "hooks/useAuth";
import useCookie from 'hooks/useCookie'

const AWSCognitoEmailVerification = () => {
    const { setCookie } = useCookie()
    const [loading, setLoading] = useState(false);
    const [resending, setResending] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { codeVerification, reSendVerificationCode } = useAuth();

    const handleResendCode = async (email:string) => {
        try {
            setResending(true);
            await reSendVerificationCode(email);
        } catch (error) {
            // @ts-ignore
            setError(error);
        } finally {
            setResending(false);
        }
    };

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Card style={{ width: '400px' }}>
                <Card.Body>
                    <Card.Title>AWSCognito Email Verification</Card.Title>
                    <Formik
                        initialValues={{
                            otp: '',
                            email: ''
                        }}
                        validationSchema={Yup.object().shape({
                            otp: Yup.string().length(6, 'Verification Code must be exactly 6 characters').required('Verification Code is required'),
                            email: Yup.string().email('Invalid email').required('Email is required')
                        })}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            try {
                                setLoading(true);
                                const res = await codeVerification(values.email, values.otp);
                                if (res) {
                                    setCookie(REGISTERED, "false");
                                    navigate(AuthenticationRoutes.Login);
                                    resetForm();
                                }
                            } catch (error) {
                                // @ts-ignore
                                setError(error);
                            } finally {
                                setLoading(false);
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="otp" style={{ marginBottom: '20px' }}>
                                    <Form.Label>Verification Code</Form.Label>
                                    <OtpInput
                                        value={values.otp}
                                        onChange={(otp: string ) => handleChange('otp')(otp)}
                                        numInputs={6}
                                        isInputNum
                                        inputStyle={{
                                            width: '40px',
                                            height: '40px',
                                            marginRight: '10px',
                                            fontSize: '16px'
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.otp}</Form.Control.Feedback>
                                </Form.Group>
                                {error && <Alert variant="danger">{error}</Alert>}
                                {loading ? (
                                    <Button variant="primary" disabled>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        Verifying...
                                    </Button>
                                ) : (
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        Verify Code
                                    </Button>
                                )}
                                <Button variant="link" onClick={() => handleResendCode(values.email)} disabled={resending}>
                                    {resending ? <Spinner animation="border" size="sm" /> : 'Re-send Code'}
                                </Button>
                                <div className='mt-1'>
                                    <span>Already Have An Account? SignIn</span>
                                    <Button
                                        className='px-1 pb-2'
                                        variant='link'
                                        href={AuthenticationRoutes.Login}
                                        >
                                        here
                                        </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default AWSCognitoEmailVerification;
