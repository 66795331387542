import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";

import useIncidentContext from "lib/hooks/useIncidentContext";
import DomesticInputBox from "components/domesticTemplate/domesticTemplatePage1/domesticInputBox";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticEvid() {
  const { generatedResult } = useIncidentContext();
  const { isEditing } = useTemplateEditingContext();
  const [isEvidencePresent, setIsEvidencePresent] = useState("");
  const [photosTaken, setPhotosTaken] = useState({
    victimInjury: false,
    suspectInjury: false,
    other: false,
    otherDescription: "",
  });
  const [otherEvidenceTypes, setOtherEvidenceTypes] = useState({
    damaged: false,
    property: false,
    videos: false,
    electronicEvidence: false,
    other: false,
    otherDescription: "",
  });
  const [destruction, setDestruction] = useState({
    isDestructed: "",
    description: "",
  });

  useEffect(() => {
    if (generatedResult) {
      const evidenceData = generatedResult["Evidence"] || "";
      setIsEvidencePresent(evidenceData["Evidence Present"] || "");
      setPhotosTaken({
        victimInjury: evidenceData["Photos Taken"] == "Victim injury",
        suspectInjury: evidenceData["Photos Taken"] == "Suspect injury",
        other: evidenceData["Photos Taken"] == "Other",
        otherDescription: evidenceData["Other Evidence"] || "",
      });
      setOtherEvidenceTypes({
        damaged: evidenceData["Other Evidence"] == "Damaged",
        property: evidenceData["Other Evidence"] == "Property",
        videos: evidenceData["Other Evidence"] == "Videos",
        electronicEvidence: evidenceData["Other Evidence"] == "Electronic Evidence",
        other: evidenceData["Other Evidence"] == "Other",
        otherDescription: evidenceData["Other Evidence"] || "",
      });
      setDestruction({
        isDestructed: evidenceData["Destruction of property"] == "Yes" ? "Yes" : "No",
        description: evidenceData["description of Destruction of property"] == "NA" ? "" : evidenceData["description of Destruction of property"],
      });
    }
  }, [generatedResult]);

  const handleEvidenceChange = (value: any) => {
    setIsEvidencePresent(value);
  };
  const handleOtherEvidenceTypes = (type: any, value: any) => {
    setOtherEvidenceTypes((prev) => {
      const newOtherEvidenceTypes = { ...prev, [type]: value };

      return newOtherEvidenceTypes;
    });
  };
  const handlePhotosTakenChange = (type: any, value: any) => {
    setPhotosTaken((prev) => {
      const newPhotosTaken = { ...prev, [type]: value };

      return newPhotosTaken;
    });
  };

  const handleDestructionChange = (input: any) => {
    setDestruction((prev) => ({
      ...prev,
      isDestructed: input,
    }));
  };

  const handleDestructionDescriptionChange = (field: any, input: any) => {
    setDestruction((prev) => ({
      ...prev,
      description: input,
    }));
  };
  return (
    <div className="d-flex flex-column w-100 border-start border-end border-top border-black border-3 p-0">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span className="no-wrap">Evid</span>
      </div>
      <div className="d-flex flex-row border-start border-end border-black border-3">
        <div
          className={classNames(
            css(styles.normalFont),
            "d-flex flex-row justify-content-start flex-wrap p-0 col-2 flex-fill border-end border-bottom border-black"
          )}
        >
          <div className="d-flex">Evidence Present?</div>
          <div className="d-flex pe-1 h-50">
            <Form.Check
              type="checkbox"
              label="Yes"
              className="m-0"
              checked={isEvidencePresent == "Yes"}
              onChange={(e) => handleEvidenceChange("Yes")}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="No"
              className="m-0"
              checked={isEvidencePresent == "No"}
              onChange={(e) => handleEvidenceChange("No")}
              disabled={!isEditing}
            />
          </div>
        </div>

        <div
          className={classNames(
            css(styles.normalFont),
            "d-flex flex-row justify-content-start flex-wrap p-0 col-3 border-end border-bottom border-black p-1"
          )}
        >
          <div className="d-flex">Photos taken:</div>
          <div className="d-flex pe-1 h-50">
            <Form.Check
              type="checkbox"
              label="Victim Injury"
              className="m-0"
              checked={photosTaken.victimInjury}
              onChange={(e) => handlePhotosTakenChange("victimInjury", e.target.checked)}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="Suspect Injury"
              className="m-0"
              checked={photosTaken.suspectInjury}
              onChange={(e) => handlePhotosTakenChange("suspectInjury", e.target.checked)}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="Other:"
              className="m-0"
              checked={photosTaken.other}
              onChange={(e) => handlePhotosTakenChange("other", e.target.checked)}
              disabled={!isEditing}
            />
          </div>

          <div className="d-flex">
            <DomesticInputBox
              defaultValue={photosTaken.otherDescription}
              userInputHandler={handlePhotosTakenChange}
              inputFieldText="otherDescription"
            />
          </div>
        </div>

        <div
          className={classNames(
            css(styles.normalFont),
            "d-flex flex-row justify-content-start flex-wrap p-0 col-4 border-end border-bottom border-black"
          )}
        >
          <div className="d-flex">Other Evidence:</div>
          <div className="d-flex pe-1 h-50">
            <Form.Check
              type="checkbox"
              label="Damaged"
              className="m-0"
              checked={otherEvidenceTypes.damaged}
              onChange={(e) => handleOtherEvidenceTypes("damaged", e.target.checked)}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="Property"
              className="m-0"
              checked={otherEvidenceTypes.property}
              onChange={(e) => handleOtherEvidenceTypes("property", e.target.checked)}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="Videos"
              className="m-0"
              checked={otherEvidenceTypes.videos}
              onChange={(e) => handleOtherEvidenceTypes("videos", e.target.checked)}
              disabled={!isEditing}
            />
          </div>

          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="Electronic Evidence"
              className="m-0"
              checked={otherEvidenceTypes.electronicEvidence}
              onChange={(e) => handleOtherEvidenceTypes("electronicEvidence", e.target.checked)}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="Other:"
              className="m-0"
              checked={otherEvidenceTypes.other}
              onChange={(e) => handleOtherEvidenceTypes("other", e.target.checked)}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex flex-fill">
            <DomesticInputBox
              defaultValue={otherEvidenceTypes.otherDescription}
              userInputHandler={handleOtherEvidenceTypes}
              inputFieldText="otherDescription"
            />
          </div>
        </div>

        <div
          className={classNames(
            css(styles.normalFont),
            "d-flex flex-row justify-content-start flex-wrap p-0 col-3 border-end border-bottom border-black"
          )}
        >
          <div className="d-flex">Destruction of Property?</div>
          <div className="d-flex pe-1 h-50">
            <Form.Check
              type="checkbox"
              label="Yes"
              className="m-0"
              checked={destruction.isDestructed == "Yes"}
              onChange={(e) => handleDestructionChange("Yes")}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="No"
              className="m-0"
              checked={destruction.isDestructed == "No"}
              onChange={(e) => handleDestructionChange("No")}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">If yes, describe:</div>
          <div className="d-flex flex-fill">
            <DomesticInputBox
              defaultValue={destruction.description}
              userInputHandler={handleDestructionDescriptionChange}
              inputFieldText="otherDescription"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
});

export default DomesticEvid;
