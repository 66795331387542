import { createContext, useContext } from "react";

interface SharedContextType {
  isEditing: boolean;
  setIsEditing: (state: boolean) => void;
  handleEditing: (state: boolean) => void;
}

const INITIAL_VALUE: SharedContextType = {
  isEditing: false,
  setIsEditing: () => {},
  handleEditing: () => {},
};

const SharedContext = createContext<SharedContextType>(INITIAL_VALUE);

function useTemplateEditingContext() {
  return useContext(SharedContext);
}

export default useTemplateEditingContext;
export { SharedContext };
