import React, { useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

interface Props {
  inputFieldText?: string;
  index?: number;
  userInputHandler?: any;
  defaultValue?: any;
}

function ColumnInputField(prop: Props) {
  const { isEditing } = useTemplateEditingContext();
  const editTextHandler = (userText: string) => {
    prop.userInputHandler(prop.index, userText);
  };

  return (
    <InputGroup size="sm" className={classNames(css(styles.inputBoxColur), "d-flex flex-column h-100 p-0 border-0 rounded-0 m-0 w-100 p-0")}>
      <InputGroup.Text className={classNames(css(styles.textBoxHeight, styles.normalFont), "text-dark  rounded-0 h-25 w-100 p-0 m-0 border-0")}>
        {prop.inputFieldText}
      </InputGroup.Text>
      <Form.Control
        className="w-100 h-75 border-0 p-0 m-0 bg-transparent rounded-0 custom-input focus-visible"
        onChange={(e) => editTextHandler(e.target.value)}
        value={prop.defaultValue}
        disabled={!isEditing}
      />
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  inputBoxColur: {
    background: "#C7E4FF",
  },
  textBoxHeight: {
    height: "20%",
  },
  normalFont: {
    fontSize: "0.9rem",
  },
});
export default ColumnInputField;
