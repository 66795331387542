import React, { useState, useContext, useEffect } from "react";

import { Button, Container, Dropdown, Row, Col, InputGroup } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { faChevronLeft, faChevronRight, faChevronDown, faPen, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { For, If } from "tsx-control-statements/components";

import GeneralRoutes from "constants/generalRoutes";
import UserTemplate from "typescript/userTemplate";
import Roles from "constants/roles";
import { useNavigate } from "react-router-dom";
import Templates from "constants/templates";
import useIncidentContext from "../lib/hooks/useIncidentContext";
import useTemplate from "hooks/api/useTemplate";
import TemplatePage1 from "components/templatePage1/templatePage1";
import TemplatePage2 from "components/templatePage2/templatePage2";
import DomesticTemplatePage1 from "components/domesticTemplate/domesticTemplatePage1/domesticTemplatePage1";
import DomesticTemplatePage2 from "components/domesticTemplate/domesticTemplatePage2/domesticTemplatePage2";

const templateElement = [
  {
    title: "Child Abuse",
    page1: <TemplatePage1 />,
    page2: <TemplatePage2 />,
  },
  {
    title: "Domestic",
    page1: <DomesticTemplatePage1 />,
    page2: <DomesticTemplatePage2 />,
  },
];

function SelectTemplate() {
  const navigate = useNavigate();
  const { incidentText, setGeneratedResult, setTemplateType } = useIncidentContext();
  const [foundTemplateElement, setFoundTemplateElement] = useState<boolean>(false);
  const { generate } = useTemplate();
  const [templateSelected, setTemplateSelected] = useState<string>("");
  const [selectTemplate, setSelectTemplate] = useState<boolean>(true);
  const [editBtnClicked, setEditBtnClicked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userCase, setUserCase] = useState<string>(Templates[0].templateStructure);
  const [currentUserTemplate, setCurrentUserTemplate] = useState<UserTemplate>(Templates[0]);

  const EditBtnHandler = () => {
    setEditBtnClicked(!editBtnClicked);
  };
  const TemplateSelectHandler = (selectedTemplate: UserTemplate) => {
    setCurrentUserTemplate(selectedTemplate);
    setUserCase(selectedTemplate.templateStructure);
    setTemplateSelected(selectedTemplate.title);
    setSelectTemplate(false);
  };
  const NextBtnHandler = async () => {
    if (loading) {
      navigate("/" + Roles.Officer + GeneralRoutes.LoadingPage);
    }
    const genTemplate = await generate({
      title: currentUserTemplate.value,
      incident: incidentText,
      templateStructure: currentUserTemplate.templateStructure,
    });
    setTemplateType(templateSelected);
    setGeneratedResult(genTemplate);
    navigate("/" + Roles.Officer + GeneralRoutes.Results);
    setLoading(false);
  };

  const BackBtnHandler = () => {
    navigate("/" + Roles.Officer + GeneralRoutes.InsertCase);
  };

  let templateItem = { title: "", page1: "", page2: "" };

  let template: UserTemplate = {
    title: "",
    description: "",
    templateStructure: "",
    value: "",
  };
  useEffect(() => {
    const templateFound = templateElement.some((templateItem) => templateItem.title === templateSelected);
    setFoundTemplateElement(templateFound);
  }, [templateSelected]);
  return (
    <Container fluid className="px-4 mx-5">
      <Row>
        <Col className="d-flex align-items-start">
          <h3 className="pe-2">Choose Template : </h3>
          <InputGroup className={css(styles.incidentType)}>
            <Dropdown>
              <Dropdown.Toggle variant="dark" id="dropdown-basic">
                <If condition={selectTemplate}>
                  SELECT
                  <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                </If>
                <If condition={!selectTemplate}>{currentUserTemplate.title}</If>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <For each="template" of={Templates}>
                  <Dropdown.Item
                    key={template.title}
                    className={css(styles.textFont)}
                    eventKey={template.title}
                    onClick={() => TemplateSelectHandler(template)}
                  >
                    <span className="">{template.title}</span>
                  </Dropdown.Item>
                </For>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </Col>
      </Row>

      <Row className="mt-3">
        <If condition={templateSelected}>
          <For each="templateItem" of={templateElement}>
            <If condition={templateItem.title == templateSelected}>
              <Col>
                <div className={classNames(css(styles.textFont, styles.textArea), "pt-0 p-5")}>
                  <div className={classNames(css(styles.TemplatePageWidth), "mb-5 pb-5")}>{templateItem.page1}</div>
                  <div className={classNames(css(styles.TemplatePageWidth))}>{templateItem.page2}</div>
                </div>
              </Col>
            </If>
          </For>
          <If condition={!foundTemplateElement}>
            <div className={css(styles.textFont, styles.textArea)}>
              <h3 className="d-flex justify-content-center">{templateSelected} Template</h3>
            </div>
          </If>
        </If>
        <If condition={!templateSelected}>
          <Col>
            <div className={css(styles.textFont, styles.textArea)}></div>
          </Col>
        </If>
      </Row>
      <Row className="my-4">
        <Col>
          <Button size="lg" variant="secondary" onClick={BackBtnHandler}>
            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
            Back
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button size="lg" onClick={NextBtnHandler} disabled={selectTemplate}>
            Next
            <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  textFont: {
    fontSize: "0.95rem",
  },
  textArea: {
    border: "2px solid",
    height: "60vh",
    maxHeight: "60vh",
    overflowY: "auto",
  },
  incidentType: {
    width: "fit-content",
  },
  TemplatePageWidth: {
    width: "100%",
  },
});

export default SelectTemplate;
