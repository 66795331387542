import React, { Suspense } from 'react';

import { css, StyleSheet } from 'aphrodite';

import Logo from 'assets/logo_horz.svg';
import Cops from 'assets/cops.jpeg';

interface Props {
  dynamicComponent: React.ComponentType<any>;
}

const AuthenticationView: React.FC<Props> = ({ dynamicComponent: DynamicComponent }) => {

  return (
    <Suspense  fallback={<div>Loading...</div>}>
      <div key={location.pathname} className={css(styles.container)}>
        <div className={css(styles.logoContainer)}>
          <div className={css(styles.leftUpContainer)}>
            <img src={Logo} className={css(styles.logoImage)}  />
          </div>
          <div className={css(styles.leftDownContainer)}>
            <img src={Cops} className={css(styles.copImage)}/>
          </div>
        </div>
        <div  key={location.pathname} className={css(styles.formContainer)}>
            <DynamicComponent />
        </div>
      </div>
      </Suspense>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  logoContainer: {
    width: '70%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  leftUpContainer: {
    padding: '20px',
    height: '18%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  leftDownContainer: {
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  formContainer: {
    width: '30%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#c7d4da',
    padding: '10px',
  },
  logoImage:{
    width: '30%',
    height: '100%',
    alignSelf: 'flex-start',
  },
  copImage: {
    width: '80%',
    height: '100%',
    alignSelf: 'flex-start',
  },
});

export default AuthenticationView;
