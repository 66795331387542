import axios from 'axios';
import io from 'socket.io-client';

import { ACCESS_TOKEN, ID_TOKEN, LOGIN_STATE, LOGIN_STATES } from 'constants/cookies';
import useCookie from 'hooks/useCookie';

const PRODUCTION_BASE_URL = 'https://api.copnote.com/api'; // Prod
const STAGING_BASE_URL = 'https://staging-api.copnote.com/api'; // Staging
const STABLE_BASE_URL = 'https://stable-api.copnote.com/api'; // Stable
const LOCALHOST_BASE_URL = 'http://localhost:8000/api'; // Local

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export const isStaging = Boolean(window.location.hostname.match(/staging|staging-api/));

export const isStable = Boolean(window.location.hostname.match(/stable|stable-api/));

const BASE_URL = isLocalhost
  ? LOCALHOST_BASE_URL
  : isStaging
  ? STAGING_BASE_URL
  : isStable
  ? STABLE_BASE_URL
  : PRODUCTION_BASE_URL;

// replace the `/api`
export const socketUrl = io(BASE_URL.replace(/(api)(?=[^api]*$)/, ''), {
  path: '/ws/socket.io/',
  transports: ['websocket']
});

const { getCookie } = useCookie()

const getHttpClient = () => {
  return axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: getCookie(ACCESS_TOKEN) },
  });
};

export default getHttpClient;
