import React from "react";

import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";

import DomesticEvid from "components/domesticTemplate/domesticTemplatePage1/domesticEvid";
import DomesticIncidentSection from "components/domesticTemplate/domesticTemplatePage1/domesticIncidentSection";
import DomesticIncidentNarrative from "components/domesticTemplate/domesticTemplatePage1/domesticIncidentNarrative";
import DomesticOffense from "components/domesticTemplate/domesticTemplatePage1/domesticOffense";
import DomesticPageContacts from "components/domesticTemplate/domesticTemplatePage1/domesticPageContacts";
import DomesticVictimP1 from "components/domesticTemplate/domesticTemplatePage1/domesticVictimP1";
import DomesticVictimReview from "components/domesticTemplate/domesticTemplatePage1/domesticVictimReview";
import DomesticSuspect from "components/domesticTemplate/domesticTemplatePage1/domesticSuspect";
import DomesticSuspectP2 from "components/domesticTemplate/domesticTemplatePage1/domesticSuspectP2";
import DomesticWitnesses from "components/domesticTemplate/domesticTemplatePage1/domesticWitnesses";

function DomesticTemplatePage1() {
  return (
    <div className={classNames(css(styles.a4Height), "pt-0 p-5")}>
      <DomesticIncidentSection />
      <DomesticVictimP1 />
      <DomesticSuspectP2 />
      <DomesticVictimReview />
      <DomesticSuspect />
      <DomesticWitnesses />
      <DomesticIncidentNarrative />
      <DomesticEvid />
      <DomesticOffense />
      <DomesticPageContacts />
    </div>
  );
}

const styles = StyleSheet.create({
  a4Height: {
    height: "1500px",
    minHeight: "1500px",
  },
});
export default DomesticTemplatePage1;
