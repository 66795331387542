import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";
import InputField from "components/domesticTemplate/domesticTemplatePage1/inputField";

import useIncidentContext from "lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

type FormData = {
  agency: string;
  ori: string;
  incidentNumber: string;
  reportedDate: string;
  reportedTime: string;
  occurredDate: string;
  occurredTime: string;
  officerInitiated: boolean;
  radioRun: boolean;
  walkIn: boolean;
  icad: boolean;
  complaintNumber: string;
  address: string;
  cityStateZip: string;
  incidentDescription?: string;
};

function DomesticIncidentSection() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [formData, setFormData] = useState<FormData>({
    agency: "",
    ori: "",
    incidentNumber: "",
    reportedDate: "",
    reportedTime: "",
    occurredDate: "",
    occurredTime: "",
    officerInitiated: false,
    radioRun: false,
    walkIn: false,
    icad: false,
    complaintNumber: "",
    address: "",
    cityStateZip: "",
  });

  useEffect(() => {
    if (generatedResult) {
      const incidentData = generatedResult["Incident"] || "";

      setFormData({
        ...formData,
        agency: incidentData.agency,
        ori: incidentData["ORI number"],
        incidentNumber: incidentData["Incident number"],
        reportedDate: incidentData["reportedDate"],
        reportedTime: incidentData["reportedTime"],
        occurredDate: incidentData["occuredDate"],
        occurredTime: incidentData["occuredTime"],
        address: `${incidentData["address"]["Street No"]} ${incidentData["address"]["Street Name"]} ${incidentData["address"]["Blding. No"]} ${incidentData["address"]["Apt No"]}`,
        officerInitiated: incidentData["incidentstatus"] == "Officer Initiated",
        radioRun: incidentData["incidentstatus"] == "Radio Run",
        walkIn: incidentData["incidentstatus"] == "Walk-in",
        icad: incidentData["incidentstatus"] == "ICAD (NYC)",
        complaintNumber: incidentData["complaint number"],
        cityStateZip: `${incidentData["address"]["City"] || ""}  ${incidentData["address"]["State"] || ""}  ${incidentData["address"]["Zip"] || ""}`,
      });
    }
  }, [generatedResult]);
  const handleCheckBox = (field: string) => {
    var value;
    if ("officerInitiated" == field) {
      value = formData.officerInitiated;
    }
    if ("radioRun" == field) {
      value = formData.radioRun;
    }
    if ("walkIn" == field) {
      value = formData.walkIn;
    }
    if ("icad" == field) {
      value = formData.icad;
    }

    setFormData({
      ...formData,
      [field]: !value,
    });
  };

  const handleInputChange = (field: any, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  return (
    <div className="d-flex flex-column w-100 border border-black border-3 p-0">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span className="no-wrap">Incident</span>
      </div>
      <div className="d-flex">
        <div className="border-start border-black border-3">
          <div className="border border-black d-flex flex-row">
            <div className="col-4 border-end border-black">
              <InputField inputFieldText="Agency:" defaultValue={formData.agency} userInputHandler={handleInputChange} field="agency" />
            </div>
            <div className="col-4 fw-bold d-flex p-0">
              <div className="col-2 border-end border-black d-flex align-items-center justify-content-center">
                <h3 className="d-flex">A</h3>
              </div>
              <div className="col-10 border-end border-black d-flex flex-column align-items-center">
                <div className="d-flex">New York State</div>
                <div className="d-flex">DOMESTIC INCIDENT REPORT</div>
              </div>
            </div>
            <div className="col-4 d-flex">
              <div className="border-end border-black">
                <InputField inputFieldText="ORI:" defaultValue={formData.ori} userInputHandler={handleInputChange} field="ori" />
              </div>
              <div>
                <InputField
                  inputFieldText="Incident #"
                  defaultValue={formData.incidentNumber}
                  userInputHandler={handleInputChange}
                  field="incidentNumber"
                />
              </div>
            </div>
          </div>

          <div className="border border-black d-flex flex-row">
            <div className="col-2 border-end border-black">
              <InputField
                inputFieldText=" Reported Date (MM/DD/YYYY)"
                defaultValue={formData.reportedDate}
                userInputHandler={handleInputChange}
                field="reportedDate"
              />
            </div>
            <div className="col-1 border-end border-black">
              <InputField
                inputFieldText=" Time (24 hours)"
                defaultValue={formData.reportedTime}
                userInputHandler={handleInputChange}
                field="reportedTime"
              />
            </div>
            <div className="col-2 border-end border-black">
              <InputField
                inputFieldText="Occurred Date (MM/DD/YYYY)"
                defaultValue={formData.occurredDate}
                userInputHandler={handleInputChange}
                field="occurredDate"
              />
            </div>
            <div className="col-1 border-end border-black">
              <InputField
                inputFieldText=" Time (24 hours)"
                defaultValue={formData.occurredTime}
                userInputHandler={handleInputChange}
                field="occurredTime"
              />
            </div>

            <div
              className={classNames(css(styles.normalFont), "col-4 border-end border-bottom border-black d-flex align-content-start flex-wrap p-0")}
            >
              <div className={classNames(css(styles.normalFont), "d-flex pe-1")}>
                <Form.Check
                  className={classNames(css(styles.normalFont))}
                  type="checkbox"
                  label="officerInitiated"
                  checked={formData.officerInitiated == true}
                  onChange={(e) => handleCheckBox("officerInitiated")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  className={classNames(css(styles.normalFont))}
                  type="checkbox"
                  label="Radio Run"
                  checked={formData.radioRun == true}
                  onChange={(e) => handleCheckBox("radioRun")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  className={classNames(css(styles.normalFont))}
                  type="checkbox"
                  label="walkIn"
                  checked={formData.walkIn == true}
                  onChange={(e) => handleCheckBox("walkIn")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  className={classNames(css(styles.normalFont))}
                  type="checkbox"
                  label="ICAD (NYC)"
                  checked={formData.icad == true}
                  onChange={(e) => handleCheckBox("icad")}
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="col-2 border-bottom">
              <InputField
                inputFieldText="Complaint #"
                defaultValue={formData.complaintNumber}
                userInputHandler={handleInputChange}
                field="complaintNumber"
              />
            </div>
          </div>
          <div className="border border-black d-flex flex-row">
            <div className="col-7 border-end border-black">
              <InputField
                inputFieldText="Address (Street No., Street Name, Bldg. No., Apt No.)"
                defaultValue={formData.address}
                userInputHandler={handleInputChange}
                field="address"
              />
            </div>
            <div className="col-5 border-end border-black">
              <InputField
                inputFieldText="City, State, Zip"
                defaultValue={formData.cityStateZip}
                userInputHandler={handleInputChange}
                field="cityStateZip"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
});
export default DomesticIncidentSection;
