import React from 'react';

function UserSecurity(){
  return (
    <div>
      <h3>User Security</h3>
    </div>
  );
}

export default UserSecurity;
