import React from 'react';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { For } from 'tsx-control-statements/components';

const ethinicityCodes = [
  '(AA) Black or African-American',
  '(AL) Alaskan Native',
  '(AS) Asian',
  '(NA) Native American',
  '(PI) Native Hawaiian/Pacifi c Islander',
  '(WH) White',
  '(XX) Other',
  '(UNK) Unknown',
];
const suspictionAndRelationCode = [
  { header: 'CHILDREN (Suspicions)', listofCodes: [['(AB) Abuse', '(MA) Maltreatment', '(NO) None', '(UN) Unknown']] },
  {
    header: 'ADULTS (Relationships)',
    listofCodes: [
      ['(P) Parents', '(PS) Parental Substitute', '(FP) Foster Parent', '(GP) Grandparent'],
      ['(R) Relative', '(N) No Relationship', '(UN) Unknown', ''],
    ],
  },
];
function CodeSection() {
  return (
    <div className="d-flex bg-light text-dark w-100">
      <div className={classNames(css(styles.normalFont, styles.ethinitycodeWidth), 'd-flex flex-column border-end border-dark')}>
        <div className={classNames(css(styles.normalFont), 'w-100 d-flex justify-content-center fw-medium')}>ETHNICITY CODE</div>
        <div className="p-3">
          <For each="ethinicityCode" of={ethinicityCodes} body={(ethinicityCode) => <Row className={classNames(css(styles.paragraphFont), 'text-dark')}>{ethinicityCode}</Row>} />
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className={classNames(css(styles.normalFont), 'd-flex justify-content-center fw-medium')}>** SUSPICION AND RELATIONSHIP CODES</div>
        <div className="p-0 pb-3 text-dark">
          <For
            each="codesLine"
            of={suspictionAndRelationCode}
            body={(codesLine) => (
              <Row className="pb-3">
                <div className={classNames(css(styles.normalFont), 'd-flex')}>{codesLine.header}</div>
                <If condition={Array.isArray(codesLine.listofCodes[0])}>
                  <For
                    each="rowOfCodes"
                    of={codesLine.listofCodes}
                    body={(rowOfCodes) => (
                      <div className="d-flex">
                        <For each="code" of={rowOfCodes} body={(code) => <Col className={classNames(css(styles.paragraphFont), 'text-dark')}>{code}</Col>} />
                      </div>
                    )}
                  />
                </If>
              </Row>
            )}
          />
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  ethinitycodeWidth: {
    width: '35%',
  },
  normalFont: {
    fontSize: '0.9rem',
  },
  paragraphFont: {
    fontSize: '0.8rem',
  },
});
export default CodeSection;
