import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'react-bootstrap/Image';
import useCurrentUser from "hooks/useCurrentUser";
import ImageUploadModal from './ImageUploadModal';
import { CURRENT_USER } from "constants/cookies";
import useCookie from 'hooks/useCookie';
import { If } from 'tsx-control-statements/components';

function UserProfileImage() {
    const { setCookie } = useCookie();
    const currentUser = useCurrentUser();
    const initialProfileImageUrl = currentUser?.profile_picture_url === '#' ? null : currentUser?.profile_picture_url;
    const [profileImageUrl, setProfileImageUrl] = useState(initialProfileImageUrl);
    const [modalShow, setModalShow] = useState(false);

    const updateProfileImage = (imgSrc: string): void => {
        currentUser.profile_picture_url = imgSrc;
        setCookie(CURRENT_USER, JSON.stringify(currentUser));
    };

    return (
        <div className={classNames(css(styles.profileImagePageContainer), 'd-flex justify-content-center align-items-center')}>
            <div className="p-0 d-flex flex-column justify-content-center">
                <If condition={profileImageUrl !== null}>
                    <Image width={200} height={200} className="p-0 m-0 pe-auto border border-primary btn" src={profileImageUrl} roundedCircle onClick={() => setModalShow(true)} />
                </If>
                <If condition={profileImageUrl === null}>
                    <Button
                        className={classNames(css(styles.defaultImageContainer), 'border border-primary rounded d-flex justify-content-center align-items-center')}
                        onClick={() => setModalShow(true)}
                        variant="outline-primary"
                    >
                        <FontAwesomeIcon icon={faUser} className="w-50 h-auto" />
                    </Button>
                </If>
                <h5 className="d-flex justify-content-center align-items-center">{currentUser?.firstName}</h5>
                <ImageUploadModal show={modalShow} onHide={() => setModalShow(false)} updateProfileImage={updateProfileImage} />
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    profileImagePageContainer: {
        width: '100%',
        minHeight: '100vh',
    },
    defaultImageContainer: {
        width: '200px',
        height: '200px',
    },
});

export default UserProfileImage;
