import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { StyleSheet, css } from "aphrodite";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { For } from "tsx-control-statements/components";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import useIncidentContext from "../../lib/hooks/useIncidentContext";

import ColumnInputField from "components/templatePage1/columnInputField";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function StateReason() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const contentEditableRef = useRef(null);

  const [selectedList, setSelectedList] = useState([
    { row1: { number: 0, text: "Medical Exam", input: false }, row2: { number: 1, text: "Photographs", input: false } },
    { row1: { number: 2, text: "X-Ray", input: false }, row2: { number: 3, text: "Hospitalization", input: false } },
    { row1: { number: 4, text: "Removal/Keeping", input: false }, row2: { number: 5, text: "Returned Home", input: false } },
    { row1: { number: 6, text: "Not, Med. Exam./Coroner", input: false }, row2: { number: 7, text: "Notifi ed D.A.", input: false } },
  ]);
  const [dateOfStateReason, setDateofStateReason] = useState([
    { text: "month", value: "" },
    { text: "day", value: "" },
    { text: "year", value: "" },
  ]);
  const [textInput, setTextInput] = useState("");
  const [timeOfStateReason, setTimeOfStateReason] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (generatedResult) {
      const actions = generatedResult?.actionsTakenOrAboutToBeTake;
      setSelectedList([
        {
          row1: { number: 0, text: "Medical Exam", input: actions?.medicalExam },
          row2: { number: 1, text: "Photographs", input: actions?.Photographs },
        },
        { row1: { number: 2, text: "X-Ray", input: actions?.XRay }, row2: { number: 3, text: "Hospitalization", input: actions?.Hospitalization } },
        {
          row1: { number: 4, text: "Removal/Keeping", input: actions?.removalKeeping },
          row2: { number: 5, text: "Returned Home", input: actions?.returnedHome },
        },
        {
          row1: { number: 6, text: "Not, Med. Exam./Coroner", input: false },
          row2: { number: 7, text: "Notifi ed D.A.", input: actions?.notifiedDA },
        },
      ]);
      setTextInput(generatedResult?.reasonOfSuspicion == "NA" ? " " : generatedResult?.reasonOfSuspicion);
      setDateofStateReason([
        { text: "month", value: generatedResult?.suspiciousDate?.month == "NA" ? " " : generatedResult?.suspiciousDate?.month },
        { text: "day", value: generatedResult?.suspiciousDate?.day == "NA" ? " " : generatedResult?.suspiciousDate?.day },
        { text: "year", value: generatedResult?.suspiciousDate?.year == "NA" ? " " : generatedResult?.suspiciousDate?.year },
      ]);
      setTimeOfStateReason(generatedResult?.time == "NA" ? " " : generatedResult?.time);
    }
  }, [generatedResult]);

  const handleCheckboxChange = (listIndex: number, row: string, value: boolean) => {
    setSelectedList((prevList) => {
      const updatedList = [...prevList];
      if (row === "row1") {
        updatedList[listIndex].row1.input = value;
      } else {
        updatedList[listIndex].row2.input = value;
      }
      return updatedList;
    });
  };
  const inputHandler = (index: number, value: any) => {
    setDateofStateReason((prevList) => {
      const updatedList = [...prevList];
      updatedList[index].value = value;
      return updatedList;
    });
  };
  const handleCheckboxChangeForTime = (input: any) => {
    setSelectedOption(input);
  };
  const handleTime = (input: any) => {
    setTimeOfStateReason(input);
  };
  const handleUserText = (input: any) => {
    setTextInput(input);
  };
  const handleInput = (e: any) => {
    handleUserText(e.currentTarget.innerHTML);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    insertTextAtCursor(text);
  };

  const insertTextAtCursor = (text: any) => {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }, [textInput]);

  return (
    <div>
      <Row className="d-flex flex-row">
        <div className={classNames(css(styles.paragraphFont), "d-flex flex-row justify-content-center w-75")}>
          State reasons for suspicion. Include the nature and extent of each child’s injuries, abuse or maltreatment, any evidence of prior injuries,
          abuse or maltreatment to the child or his siblings and any evidence or suspicions of “Parental” behavior contributing to the problem.
        </div>
        <div className="w-25 d-flex flex-column">
          <div className={classNames(css(styles.paragraphFont))}>(If known, give time and date of alleged incident):</div>
          <div className="d-flex flex-row">
            <div className="w-50 d-flex flex-row">
              <div className="border-bottom border-end border-dark">
                <ColumnInputField inputFieldText="Month" index={0} userInputHandler={inputHandler} defaultValue={dateOfStateReason[0].value} />
              </div>
              <div className="border-bottom border-end border-dark">
                <ColumnInputField inputFieldText="Day" index={1} userInputHandler={inputHandler} defaultValue={dateOfStateReason[1].value} />
              </div>
              <div className="border-bottom border-dark">
                <ColumnInputField inputFieldText="Year" index={2} userInputHandler={inputHandler} defaultValue={dateOfStateReason[2].value} />
              </div>
            </div>
            <div className="w-50 d-flex flex-row h-100">
              <InputGroup size="sm" className={classNames(css(styles.normalFont), "h-100 d-flex align-items-center w-100 align-items-end")}>
                <InputGroup.Text className="text-dark border-0 rounded-0 d-flex justify-content-end">Time</InputGroup.Text>
                <Form.Control
                  className={classNames(css(styles.inputBoxColur), "border-0 rounded-0")}
                  onChange={(e) => handleTime(e.target.value)}
                  value={timeOfStateReason}
                  disabled={!isEditing}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </Row>
      <Row className="pt-1">
        <div
          className={classNames(
            css(styles.inputBoxColur, styles.reasonOfSuspicionTextBox, styles.textareaOfReason),
            "border-0 rounded-0 overflow-scroll d-flex text-break"
          )}
          contentEditable={isEditing}
          ref={contentEditableRef}
          onInput={handleInput}
          onPaste={handlePaste}
          dangerouslySetInnerHTML={{ __html: textInput }}
        ></div>
      </Row>
      <Row>
        <Col>Actions Taken or About To Be Taken:</Col>
        <For
          each="selectedColoumn"
          of={selectedList}
          body={(selectedColoumn, index) => (
            <Col>
              <Row>
                <div className="d-flex flex-row">
                  <div>{selectedColoumn.row1.number}</div>
                  <Form.Check
                    inline
                    label={selectedColoumn.row1.text}
                    name="group1"
                    type="checkbox"
                    checked={selectedColoumn.row1.input}
                    onChange={() => handleCheckboxChange(index, "row1", !selectedColoumn.row1.input)}
                    disabled={!isEditing}
                  />
                </div>
              </Row>
              <Row className="d-flex flex-row">
                <div className="d-flex flex-row">
                  <div className="d-flex">{selectedColoumn.row2.number}</div>
                  <Form.Check
                    inline
                    label={selectedColoumn.row2.text}
                    name="group1"
                    type="checkbox"
                    onChange={() => handleCheckboxChange(index, "row2", !selectedColoumn.row2.input)}
                    disabled={!isEditing}
                  />
                </div>
              </Row>
            </Col>
          )}
        />
      </Row>
    </div>
  );
}
const styles = StyleSheet.create({
  inputBoxColur: {
    background: "#C7E4FF",
  },
  normalFont: {
    fontSize: "0.85rem",
  },
  paragraphFont: {
    fontSize: "0.8rem",
  },
  reasonOfSuspicionTextBox: {
    height: "200px",
  },
  textareaOfReason: {
    whiteSpace: "pre-wrap",
    overFlowWrap: "break-word",
    lineBreak: "auto",
  },
});

export default StateReason;
