import { createContext, useContext } from 'react';

const INITIAL_VALUE: any = {};
const SharedContext = createContext(INITIAL_VALUE);

function useSharedContext() {
    return useContext(SharedContext);
  }
  
  export default useSharedContext;
  export { SharedContext };
  