import React, { useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

interface Props {
  userInputHandler?: any;
  index?: number;
  text?: string;
  defaultValue?: any;
}

function NormalInput(prop: Props) {
  const { isEditing } = useTemplateEditingContext();
  const EditTextHandler = (userText: string) => {
    prop.userInputHandler(prop.index, prop.text, userText);
  };
  return (
    <InputGroup
      size="sm"
      className={classNames(css(styles.inputBoxColur, styles.normalFont), "d-flex flex-row h-100 p-0 border-0 rounded-0 w-100 p-0")}
    >
      <Form.Control
        className="h-100 border-0 p-0 m-0 bg-transparent rounded-0 w-100"
        onChange={(e) => EditTextHandler(e.target.value)}
        value={prop.defaultValue}
        disabled={!isEditing}
      />
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  inputBoxColur: {
    background: "#C7E4FF",
  },
  normalFont: {
    fontSize: "0.9rem",
  },
});
export default NormalInput;
