import React, { Suspense } from 'react';

import AuthenticationView from "components/auth/AuthenticationView";
import Login from "components/auth/Login/Login";

const LoginPage: React.FC = () => {
    return (
        <Suspense  fallback={<div>Loading...</div>}>
            <div>
                <AuthenticationView dynamicComponent={Login} />
            </div>
        </Suspense>
    );
};

export default LoginPage;
