import React, { useEffect, useState } from "react";

import classNames from "classnames";
import Col from "react-bootstrap/Col";
import { css, StyleSheet } from "aphrodite";
import { For } from "tsx-control-statements/components";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import useIncidentContext from "../../lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

const SuspicionsDetails = [
  [
    { text: "Name", value: "" },
    { text: "Address", value: "" },
  ],
  [
    { text: "Borough", value: "" },
    { text: "Telephone No.", value: "" },
    { text: "Relation to Victim", value: "" },
  ],
];

function ComplainantInformation() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [Suspicions, setSuspicions] = useState(SuspicionsDetails);

  useEffect(() => {
    if (generatedResult) {
      const data = generatedResult?.complainantInformation;
      setSuspicions([
        [
          { text: "Name", value: data?.name == "NA" ? " " : data?.name },
          { text: "Address", value: data?.address == "NA" ? " " : data?.address },
        ],
        [
          { text: "Borough", value: data?.borough == "NA" ? " " : data?.borough },
          { text: "Telephone No.", value: data?.telephone == "NA" ? " " : data?.telephone },
          { text: "Relation to Victim", value: data?.relationshipToVictim == "NA" ? " " : data?.relationshipToVictim },
        ],
      ]);
    }
  }, [generatedResult]);

  const inputHandler = (index1: number, index2: number, value: any) => {
    setSuspicions((prevList) => {
      const updatedList = [...Suspicions];
      updatedList[index1][index2].value = value;
      return updatedList;
    });
  };
  return (
    <div className={classNames(css(styles.normalFont))}>
      <div className="d-flex flex-row justify-content-center border-top border-bottom border-dark bg-secondary bg-opacity-75">
        Complainant Information
      </div>
      <div>
        <For
          each="rowitems"
          of={Suspicions}
          body={(rowitems, index1) => (
            <Row className="border-bottom border-dark">
              <For
                each="rowitem"
                of={rowitems}
                body={(rowitem, index2) => (
                  <Col>
                    <InputGroup
                      size="sm"
                      className={classNames(css(styles.inputBoxColur), "d-flex flex-column h-100 p-0 border-0 rounded-0 m-0 w-100 p-0")}
                    >
                      <InputGroup.Text
                        className={classNames(css(styles.textBoxHeight, styles.normalFont), "text-dark  rounded-0 h-25 w-100 p-0 m-0 border-0")}
                      >
                        {rowitem.text}
                      </InputGroup.Text>
                      <Form.Control
                        className="w-100 h-75 border-0 p-0 m-0 bg-transparent rounded-0 custom-input focus-visible"
                        onChange={(e) => inputHandler(index1, index2, e.target.value)}
                        value={Suspicions[index1][index2].value}
                        disabled={!isEditing}
                      />
                    </InputGroup>
                  </Col>
                )}
              />
            </Row>
          )}
        />
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.9rem",
  },
  inputBoxColur: {
    background: "#C7E4FF",
  },
  textBoxHeight: {
    height: "20%",
  },
});
export default ComplainantInformation;
