import React from 'react';

import { css, StyleSheet } from 'aphrodite';

interface Props{
    audioUrl : string
};

function AudioPlayerModal({audioUrl}:Props) {
  return (
    <div>
      <div className={css(styles.centeredContainer)}>
            <audio controls className="w-100">
              <source src={audioUrl} type="audio/mpeg"/>
              Your browser does not support the audio element.
            </audio>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
    centeredContainer: {
        backgroundColor: 'var(--Primary-Gray-50, #F9FAFB)',
        borderRadius: '2%',
        paddingBottom: '2rem',
        paddingRight: '8rem'
    }
});

export default AudioPlayerModal;
