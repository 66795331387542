import React from "react";

function useScreenSize() {
  const isMobileScreen = () => {
    if (window.screen.availWidth <= 1200) return true;
    return false;
  };

  return { isMobileScreen };
}

export default useScreenSize;
