import React from 'react';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import ListGroup from 'react-bootstrap/ListGroup';

import SectionsDetails from 'components/templatePage2/sectionDetails';

function AbstractSection() {
  return (
    <div className="p-0 w-100  bg-light">
      <div className="d-flex flex-row p-2 border-top border-bottom border-dark w-100 p-0 m-0 fw-medium">Abstract Sections from Article 6, Title 6, Social Services Law</div>
      <div className="p-3">
        <div className="d-flex flex-column">
          <div className={classNames(css(styles.normalFont), 'fw-bold')}>Section 412. Definitions.</div>
          <ListGroup as="ol" numbered>
            <ListGroup.Item as="li" className="border-0 d-flex justify-content-between align-items-start m-0 p-0 bg-light text-dark">
              <SectionsDetails
                titleOfSection="Definition of Child Abuse."
                Description="(see N.Y.S. Family Court Act, Section 1012(e))"
                Details="An “abused child” is a child less than eighteen years of age whose parent or other person legally responsible for his care:"
                listOfDetails={[
                  'Inflicts or allows to be infl icted upon the child serious physical injury, or',
                  'Creates or allows to be created a substantial risk of physical injury, or',
                  'Commits or allows to be committed against the child a sexual offense as defi ned in the penal law.',
                ]}
              />
            </ListGroup.Item>
            <ListGroup.Item as="li" className="border-0 d-flex justify-content-between align-items-start m-0 p-0 bg-light text-dark">
              <SectionsDetails
                titleOfSection="Definition of Child Maltreatment. "
                Description="(see N.Y.S. Family Court Act, Section 1012(f))"
                Details="A “maltreated child” is a child under eighteen years of age who has had serious physical injury infl icted upon him by other than
          accidental means A “maltreated child” is also a child under eighteen years of age whose physical, mental or emotional condition has been impaired
          or is in danger of becoming impaired as a result of the failure of his parent or other person legally responsible for his care to
          exercise a minimum degree of care:"
                listOfDetails={[
                  'in supplying the child with adequate food, clothing, shelter, education, medical or surgical care though fi nancially able to do so or offered fi nancial or other reasonable means to do so; or',
                  'in providing the child with proper supervision or guardianship; or',
                  'by unreasonable infl icting, or allowing to be infl icted, harm or a substantial risk thereof, including the infl iction of excessive corporal punishment; or',
                  'by using a drug or drugs; or',
                  'by using alcoholic beverages to the extent that he loses self-control of his actions; or',
                  'by any other acts of a similarly serious nature requiring the aid of the Family Court.',
                ]}
              />
            </ListGroup.Item>
          </ListGroup>
        </div>
        <SectionsDetails
          titleOfSection="Section 415. Reporting Procedure."
          Description="Reports of suspected child abuse or maltreatment shall be made immediately by telephone*
and in writing within 48 hours after such oral report. . . . . written reports shall be made to the appropriate local child protective services
on this form (Report of Suspected Child Abuse and Maltreatment, DSS-2221-A)."
          listOfDetails={[]}
        />
        <SectionsDetails
          titleOfSection="Section 419. Immunity from Liability."
          Description=" Any person, official, or institution participating in good faith in the making of a report, the taking
          of photographs, or the removal or keeping of a child pursuant to this title shall have immunity from any liability, civil or criminal, that
          might otherwise result by reason of such actions. For the purpose of any proceeding, civil or criminal, the good faith of any person
          required to report cases of child abuse or maltreatment shall be presumed."
          listOfDetails={[]}
        />
        <div className="d-flex flex-column">
          <div className={classNames(css(styles.normalFont), 'fw-bold')}>Section 420. Penalties for Failure to Report.</div>
          <ListGroup as="ol" numbered className="p-0">
            <ListGroup.Item as="li" className="border-0 d-flex justify-content-between align-items-start m-0 p-0 bg-light text-dark">
              <SectionsDetails
                Description="Any person, offi cial or institution required by this title to report a case of suspected child abuse or maltreatment who, willfully
                fails to do so shall be guilty of a class A misdemeanor."
              />
            </ListGroup.Item>
            <ListGroup.Item as="li" className="border-0 d-flex justify-content-between align-items-start m-0 p-0 bg-light text-dark">
              <SectionsDetails
                Description="Any person, offi cial or institution required by this title to report a case of suspected child abuse or maltreatment who knowingly
                and willfully fails to do so shall be civilly liable for the damages proximately caused by such failure."
              />
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className={classNames(css(styles.paragraphFont), 'text-dark p-3 pt-4 pb-0')}>* New York State Child Abuse and Maltreatment Register — 1-800-635-1522</div>
        <div className="text-dark p-3 pt-0">Information regarding ACS investigations and Child Protection Specialists assigned — 718 KID-SAFE (718 543-7233)</div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: '0.9rem',
  },
  paragraphFont: {
    fontSize: '0.8rem',
  },
});
export default AbstractSection;
