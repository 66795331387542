enum GeneralRoutes {
  Home = '/',
  SelectTemplate = '/template',
  ViewCase = '/view',
  Results = '/results',
  InsertCase = '/insert',
  FindVictim = '/find-victim',
  LoadingPage = "/loadingPage",
}

enum AuthenticationRoutes {
  Login = '/login',
  Register = '/register',
  CodeVerification = '/code-verification',
  ForgotPassword = '/forgot-password',
}

enum UserProfileRoutes {
  UserAccount = "/account",
  UserSecurity = "/security",
}

export { AuthenticationRoutes, UserProfileRoutes };
export default GeneralRoutes;
