import React from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

interface Props {
  inputFieldText?: string;
  userInputHandler?: any;

  defaultValue?: any;
}

function DomesticInputBox(prop: Props) {
  const { isEditing } = useTemplateEditingContext();
  const EditTextHandler = (userText: any) => {
    prop.userInputHandler(prop.inputFieldText, userText);
  };
  return (
    <InputGroup size="sm" className={classNames(css(styles.normalFont), "d-flex flex-row h-100 p-0 border-0 rounded-0 w-100 p-0")}>
      <Form.Control
        className={classNames(css(styles.inputBoxColur, styles.inputBoxWidth, styles.textBoxFont), "h-100 border-0 p-0 m-0 rounded-0")}
        onChange={(e) => EditTextHandler(e.target.value)}
        value={prop.defaultValue}
        disabled={!isEditing}
      />
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  inputBoxColur: {
    background: "White",
  },
  inputBoxWidth: {
    maxWidth: "100%",
  },

  normalFont: {
    fontSize: "0.9rem",
  },
  textBoxFont: {
    fontSize: "0.8rem",
  },
});
export default DomesticInputBox;
