import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter as Router } from "react-router-dom";

import { applyCanvasRenderFix } from 'lib/canvasRenderFix';
import NYPD from "containers/NYPD";
import "./styles/nypd.scss";
import { AWSCognitoProvider } from "contexts/AWSCognitoContext";
import SharedContextProvider from "./lib/contexts/SharedContextProvider";
import TemplateEditingContext from "lib/contexts/TemplateEditContext";

const element = document.getElementById("root");
if (!element) {
  throw new Error("Element not found");
}


applyCanvasRenderFix();

const root = ReactDOM.createRoot(element);
root.render(
  <StrictMode>
    <Router>
      <AWSCognitoProvider>
        <SharedContextProvider>
          <TemplateEditingContext>
            <NYPD />
          </TemplateEditingContext>
        </SharedContextProvider>
      </AWSCognitoProvider>
    </Router>
  </StrictMode>
);
