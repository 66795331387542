import React from "react";

import { Button, Container, Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import {
  faFilter,
  faArrowDownWideShort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { For } from "tsx-control-statements/components";

import CaseCard from "components/viewComponents/CaseCard";
import caseArray from "constants/caseList";
import Colors from "constants/colors";
import FilterList from "constants/filterList";

function ViewCase() {
  const sortButtonClick = () => {
    // insert sort algorithm
  };

  const filterButtonClick = (selectedFilter: string) => {
    // insert filter
  };

  return (
    <Container
      className={classNames(css(styles.centeredContainer), "w-100 p-5 h-100")}
    >
      <div className="mb-2 h-100">
        <div className={classNames(css(styles.caseHeader))}>
        <div className="mb-4">
          <h1 className={classNames(css(styles.headerTopic), "fw-bold")}>
            View Case
          </h1>
        </div>
        <div className="mb-3 h-1 d-flex justify-content-between">
          <Button variant="link" onClick={sortButtonClick}>
            <FontAwesomeIcon
              icon={faArrowDownWideShort}
              size={"lg"}
              className={classNames(css(styles.icon), "mx-2")}
            />
          </Button>
          <Dropdown>
            <Dropdown.Toggle variant="link" className="bg-white link-light">
              <FontAwesomeIcon
                icon={faFilter}
                size={"lg"}
                className={classNames(css(styles.icon), "mx-2")}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className={"bg-white"}>
              <For
                each="filter"
                of={filterArray}
                body={(filter) => (
                  <Dropdown.Item
                    key={filter}
                    className={classNames(
                      css(styles.dropDownItem),
                      "text-dark"
                    )}
                    onClick={() => filterButtonClick(filter)}
                  >
                    {filter}
                  </Dropdown.Item>
                )}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        </div>
        <div className={classNames(css(styles.caseBody),"d-flex flex-column gap-3 overflow-auto")}>
          <For
            each="cases"
            of={caseArray}
            body={(caseData) => (
              <CaseCard key={caseData.id} caseData={caseData} />
            )}
          />
        </div>
      </div>
    </Container>
  );
}

const filterArray = Object.values(FilterList);

const styles = StyleSheet.create({
  centeredContainer: {
    backgroundColor: Colors.white,
  },
  caseHeader: {
    height: "20%"
  },
  caseBody: {
    height: "80%"
  },
  headerTopic: {
    fontSize: "1.5rem",
    color: Colors.black,
  },
  icon: {
    color: Colors.lightGray,
  },
  dropDownItem: {
    ":hover": {
      backgroundColor: Colors.gray,
      color: Colors.white,
    },
  },
});

export default ViewCase;
