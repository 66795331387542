import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";

import InputField from "components/domesticTemplate/domesticTemplatePage1/inputField";
import useIncidentContext from "lib/hooks/useIncidentContext";
import DomesticInputBox from "components/domesticTemplate/domesticTemplatePage1/domesticInputBox";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticVictimP1() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    cityStateZip: "",
    contactMethod: "",
    dob: "",
    age: "",
    selfIdentified: "",
    gender: "",
    victimPhoneNumber: "",
    language: "",
    race: "",
    raceDiscription: "",
    ethnicity: "",
  });
  useEffect(() => {
    if (generatedResult && generatedResult["Victim"]) {
      const victim = generatedResult["Victim"] || "";
      setFormData({
        name: victim["name"],
        address: `${victim["address"]["Street No"]}  ${victim["address"]["Street Name"]}  ${victim["address"]["Blding. No"]}  ${victim["address"]["Apt No"]}`,
        cityStateZip: `${victim["address"]["City"] || ""}  ${victim["address"]["State"] || ""}  ${victim["address"]["Zip"] || ""}`,
        contactMethod: victim["safeContact"],
        dob: victim["birthDate"],
        age: victim["age"],
        selfIdentified: victim["self-Identified"],
        gender: victim["gender"],
        victimPhoneNumber: victim["PhoneNumber"],
        language: victim["language"],
        race: victim["race"],
        raceDiscription: victim["race Description"],
        ethnicity: victim["ethnicity"],
      });
    }
  }, [generatedResult]);
  const handleInputChange = (field: any, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  return (
    <div className="d-flex flex-column w-100 border-start border-end border-black border-3 p-0">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span className="no-wrap">Victim(P1)</span>
      </div>
      <div className="d-flex">
        <div className="col-7 border-start border-black border-3">
          <div className="border-end border-bottom border-black">
            <InputField
              inputFieldText="Name (Last, First, M.I.) (Include Aliases)"
              defaultValue={formData.name}
              userInputHandler={handleInputChange}
              field={"name"}
            />
          </div>
          <div className="border-end border-bottom border-black">
            <InputField
              inputFieldText="Address (Street No., Street Name, Bldg. No., Apt No.)"
              defaultValue={formData.address}
              userInputHandler={handleInputChange}
              field={"address"}
            />
          </div>
          <div className="border-end border-bottom border-black">
            <InputField
              inputFieldText="City, State, Zip "
              defaultValue={formData.cityStateZip}
              userInputHandler={handleInputChange}
              field={"cityStateZip"}
            />
          </div>
          <div className="border-end border-bottom border-black">
            <InputField
              inputFieldText="How can we safely contact you? "
              defaultValue={formData.contactMethod}
              userInputHandler={handleInputChange}
              field={"contactMethod"}
            />
          </div>
        </div>
        <div className="col-5 d-flex flex-column justify-content-around h-100 p-0">
          <div className="d-flex flex-row p-0 m-0">
            <div className="col-5">
              <div className="d-flex flex-row">
                <div className="border-end border-bottom border-black w-75">
                  <InputField inputFieldText="DOB (MM/DD/YYYY)" defaultValue={formData.dob} userInputHandler={handleInputChange} field={"dob"} />
                </div>
                <div className="border-end border-bottom border-black w-25">
                  <InputField inputFieldText="Age" defaultValue={formData.age} userInputHandler={handleInputChange} field={"age"} />
                </div>
              </div>
            </div>

            <div
              className={classNames(
                css(styles.normalFont),
                "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-7"
              )}
            >
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Female"
                  className="m-0"
                  checked={formData.gender == "Female"}
                  onChange={() => handleInputChange("gender", "Female")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Male"
                  className="m-0"
                  checked={formData.gender == "Male"}
                  onChange={() => handleInputChange("gender", "Male")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Self-Identified:"
                  className="m-0"
                  checked={formData.gender == "Self-Identified:"}
                  onChange={() => handleInputChange("gender", "Self-Identified:")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <DomesticInputBox defaultValue={formData.selfIdentified} userInputHandler={handleInputChange} inputFieldText="selfIdentified" />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row">
            <div className="col-5">
              <div className="d-flex flex-row border-end border-bottom border-black">
                <InputField
                  inputFieldText="Victim Phone Number:"
                  defaultValue={formData.victimPhoneNumber}
                  userInputHandler={handleInputChange}
                  field={"victimPhoneNumber"}
                />
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex flex-row border-end border-bottom border-black">
                <InputField inputFieldText="Language:" defaultValue={formData.language} userInputHandler={handleInputChange} field={"language"} />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row flex-fill">
            <div
              className={classNames(
                css(styles.normalFont),
                "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-5"
              )}
            >
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="White"
                  className="m-0"
                  checked={formData.ethnicity == "White"}
                  onChange={() => handleInputChange("ethnicity", "White")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Black"
                  className="m-0"
                  checked={formData.ethnicity == "Black"}
                  onChange={() => handleInputChange("ethnicity", "Black")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Asian"
                  className="m-0"
                  checked={formData.ethnicity == "Asian"}
                  onChange={() => handleInputChange("ethnicity", "Asian")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="American Indian"
                  className="m-0"
                  checked={formData.ethnicity == "American Indian"}
                  onChange={() => handleInputChange("ethnicity", "American Indian")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Other"
                  className="m-0"
                  checked={formData.ethnicity == "Other"}
                  onChange={() => handleInputChange("ethnicity", "Other")}
                  disabled={!isEditing}
                />
              </div>
            </div>

            <div
              className={classNames(
                css(styles.normalFont),
                "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-7"
              )}
            >
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Hispanic"
                  className="m-0"
                  checked={formData.race == "Hispanic"}
                  onChange={() => handleInputChange("race", "Hispanic")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Non Hispanic"
                  className="m-0"
                  checked={formData.race == "Non-Hispanic"}
                  onChange={() => handleInputChange("race", "Non-Hispanic")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Unknown"
                  className="m-0"
                  checked={formData.race == "Unknown"}
                  onChange={() => handleInputChange("race", "Unknown")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <DomesticInputBox defaultValue={formData.raceDiscription} userInputHandler={handleInputChange} inputFieldText="raceDiscription" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
});
export default DomesticVictimP1;
