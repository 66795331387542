import getHttpClient from 'services/middleware/axios';

const httpClient = getHttpClient();

const UserAPI = {
    updateProfilePicture: async (formData: FormData) => {
        try {
            const response = await httpClient.put('/update_profile_picture', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.status === 200) {
                return response.data.s3_url;
            } else {
                throw new Error('Failed to update profile picture');
            }
        } catch (error) {
            throw new Error('Failed to update profile picture');
        }
    },
};

export default UserAPI;
