import React, { useCallback, useEffect, useRef, useState } from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Swal from "sweetalert2";

import { faChevronRight, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AudioVisualizer from "./AudioVisualizer";
import Colors from "constants/colors";
import GeneralRoutes from "constants/generalRoutes";
import Roles from "constants/roles";
import SpeechToText from "./speechToText/SpeechToText";
import { useNavigate } from "react-router-dom";
import useIncidentContext from "../lib/hooks/useIncidentContext";

function InsertCase() {
  const textAreaRef = useRef(null);
  const transcribedData = useRef("");
  const navigate = useNavigate();
  const { incidentText, setIncidentText } = useIncidentContext();
  // @ts-ignore
  const { isRecording, setIsRecording, onStart, onStop } = SpeechToText(textAreaRef, transcribedData);

  useEffect(() => {
    if (incidentText) {
      transcribedData.current = incidentText;
      if (textAreaRef.current) {
        // @ts-ignore
        textAreaRef.current.value = incidentText;
      }
    }
  }, [incidentText]);

  const handleNextButtonClick = () => {
    // @ts-ignore
    const insertedText = textAreaRef?.current.value;
    if (!insertedText) {
      Swal.fire({
        title: "Warning!",
        text: "Please insert a text!",
        icon: "warning",
      });
      return;
    }
    setIncidentText(insertedText);
    navigate("/" + Roles.Officer + GeneralRoutes.SelectTemplate, {
      state: { incidentText },
    });
  };

  const handleClearAll = () => {
    setIncidentText("");
    transcribedData.current = "";
    if (textAreaRef.current) {
      // @ts-ignore
      textAreaRef.current.value = "";
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    transcribedData?.current = event.target.value;
  };

  const handleClick = () => {
    setIsRecording((prevClicked) => !prevClicked);
    if (!isRecording) {
      onStart();
      return;
    }
    onStop();
  };

  return (
    <Container fluid className="px-4 mx-5">
      <Row>
        <Col className="d-flex flex-row justify-content-between">
          <h3>Tell Us What happened?</h3>
          <Button variant="secondary" size="sm" onClick={handleClearAll}>
            <span>Clear All</span>
          </Button>
        </Col>
        <div className="mt-3">
          <Form className="">
            <Form.Control as="textarea" placeholder="Type here..." ref={textAreaRef} onChange={handleInputChange} className={css(styles.textArea)} />
          </Form>
        </div>
      </Row>
      <Row>
        <Col md={{ span: 2, offset: 5 }} className="d-flex flex-column align-items-center">
          <Choose>
            <When condition={isRecording}>
              <AudioVisualizer isRecording={isRecording} />
              <div onClick={handleClick} className={classNames(css(styles.circle), "d-flex justify-content-center align-items-center bg-dark")}>
                <FontAwesomeIcon
                  icon={faMicrophone}
                  color={Colors.Merino}
                  className={classNames(css(styles.icon), "justify-content-center fa-spin")}
                />
              </div>
            </When>
            <Otherwise>
              <div className={css(styles.visualizer)} />
              <div onClick={handleClick} className={classNames(css(styles.circle), "flex-center background-white")}>
                <FontAwesomeIcon icon={faMicrophone} className={classNames(css(styles.icon), "justify-content-center")} />
              </div>
            </Otherwise>
          </Choose>
        </Col>
        <Col className="my-auto d-flex justify-content-end">
          <Button onClick={handleNextButtonClick} size="lg" disabled={isRecording}>
            <span>Next</span>
            <FontAwesomeIcon size="sm" icon={faChevronRight} className="ms-2" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  circle: {
    position: "relative",
    borderRadius: "50%",
    width: "12vh",
    height: "12vh",
    minHeight: "60px",
    minWidth: "60px",
    border: "1px solid",
    cursor: "pointer",
  },
  icon: {
    fontSize: "5vh",
    minHeight: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  textArea: {
    border: "2px solid",
    height: "55vh",
    maxHeight: "55vh",
    overflowY: "auto",
  },
  visualizer: {
    height: "2.5rem",
  },
});

export default InsertCase;
