import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";

import DomesticInputBox from "components/domesticTemplate/domesticTemplatePage1/domesticInputBox";
import InputField from "components/domesticTemplate/domesticTemplatePage1/inputField";
import useIncidentContext from "lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticOffense() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [formData, setFormData] = useState({
    offenseCommitted: "",
    suspectArrested: "",
    arrestExplanation: "",
    offense1: "",
    law1: "",
    offense2: "",
    law2: "",
  });

  useEffect(() => {
    if (generatedResult && generatedResult["Offence"] && generatedResult["OffencesSuspect"]) {
      const Offence = generatedResult.Offence;
      const Offences = generatedResult["OffencesSuspect"];
      setFormData({
        offenseCommitted: Offence["Committed"],
        suspectArrested: Offence["was suspect arrested"],
        arrestExplanation: Offence["not arrested explanation"],
        offense1: Offences.offence1 || "",
        law1: Offences.law1 || "",
        offense2: Offences.offence2 || "",
        law2: Offences.law2 || "",
      });
    }
  }, [generatedResult]);

  const handleInputChange = (field: any, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  return (
    <div className="d-flex flex-column w-100 border-start border-end border-top border-black border-3 p-0">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span className="no-wrap">Offense</span>
      </div>
      <div className="d-flex flex-row border-start border-end border-black border-3">
        <div
          className={classNames(
            css(styles.normalFont),
            "d-flex flex-row justify-content-start flex-wrap p-0 col-3 flex-fill border-end border-bottom border-black"
          )}
        >
          <div className="d-flex">Offense Committed?</div>
          <div className="d-flex pe-1 h-50">
            <Form.Check
              type="checkbox"
              label="Yes"
              className="m-0"
              onChange={() => handleInputChange("offenseCommitted", "Yes")}
              checked={formData.offenseCommitted === "Yes"}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="No"
              className="m-0"
              onChange={() => handleInputChange("offenseCommitted", "No")}
              checked={formData.offenseCommitted === "No"}
              disabled={!isEditing}
            />
          </div>
        </div>

        <div
          className={classNames(
            css(styles.normalFont),
            "d-flex flex-row justify-content-start flex-wrap p-0 col-3 border-end border-bottom border-black p-1"
          )}
        >
          <div className="d-flex">Was suspect arrested?</div>
          <div className="d-flex pe-1 h-50">
            <Form.Check
              type="checkbox"
              label="Yes"
              className="m-0"
              onChange={() => handleInputChange("suspectArrested", "Yes")}
              checked={formData.suspectArrested === "Yes"}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">
            <Form.Check
              type="checkbox"
              label="No"
              className="m-0"
              onChange={() => handleInputChange("suspectArrested", "No")}
              checked={formData.suspectArrested === "No"}
              disabled={!isEditing}
            />
          </div>
          <div className="d-flex pe-1">If no, explain:</div>

          <div className="d-flex">
            <DomesticInputBox defaultValue={formData.arrestExplanation} userInputHandler={handleInputChange} inputFieldText="arrestExplanation" />
          </div>
        </div>

        <div className="d-flex flex-row col-3 border-end border-bottom border-black">
          <InputField inputFieldText="Offense 1" userInputHandler={handleInputChange} defaultValue={formData.offense1} field={"offense1"} />
        </div>

        <div className="d-flex flex-row col-1    border-end border-bottom border-black">
          <InputField inputFieldText="Law (e.g. PL) " userInputHandler={handleInputChange} defaultValue={formData.law1} field={"law1"} />
        </div>
        <div className="d-flex flex-row col-3 border-end border-bottom border-black">
          <InputField inputFieldText="Offense 2" userInputHandler={handleInputChange} defaultValue={formData.offense2} field={"offense2"} />
        </div>
        <div className="d-flex flex-row col-1 border-end border-bottom border-black">
          <InputField inputFieldText="Law (e.g. PL)" userInputHandler={handleInputChange} defaultValue={formData.law2} field={"law2"} />
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
});
export default DomesticOffense;
