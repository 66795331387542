import React, { useState } from 'react';

import { Formik } from 'formik';

import * as Yup from 'yup';
import { Button, Card, Form, Spinner } from "react-bootstrap";
import OtpInput from 'react18-input-otp';
import { useNavigate } from 'react-router-dom';

import { AuthenticationRoutes } from 'constants/generalRoutes';
import useAuth from "hooks/useAuth";

const AWSCognitoAuthResetPassword = () => {
    const { resetPassword, forgotPassword } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);
    const [resendCodeLoading, setResendCodeLoading] = useState<boolean>(false); // State for tracking resend code loading

    const handleResendCode = async () => {
        try {
            const email = localStorage.getItem("email")
            if (email) {
                setResendCodeLoading(true);
                await forgotPassword(email);
                setResendCodeLoading(false);
            }
        } catch (err) {
            setResendCodeLoading(false);
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <Card style={{ width: '30rem' }} className="p-4">
                <Formik
                    initialValues={{
                        otp: '',
                        password: '',
                        confirmPassword: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        otp: Yup.string().max(255).required('Verification Code is required'),
                        password: Yup.string().max(255).required('Password is required'),
                        confirmPassword: Yup.string()
                            .required('Confirm Password is required')
                            .test('confirmPassword', 'Both Password must be match!', (confirmPassword, yup) => yup.parent.password === confirmPassword)
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            setIsSubmittingForm(true);
                            await resetPassword(localStorage.getItem('email'),values.otp, values.confirmPassword);
                            setSubmitting(false);
                            setIsSubmittingForm(false);

                            setTimeout(() => {
                                navigate(AuthenticationRoutes.Login, { replace: true });
                            }, 1500);
                        } catch (err) {
                            // @ts-ignore
                            setErrors({ submit: err });
                            setIsSubmittingForm(false);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="verification-code">
                                <Form.Label>Enter Verification Code</Form.Label>
                                <OtpInput
                                    value={values.otp}
                                    onChange={(otp: string) => setFieldValue('otp', otp)}
                                    numInputs={6}
                                    isInputNum
                                    containerStyle={{ justifyContent: 'space-between' }}
                                    inputStyle={{
                                        width: '100%',
                                        margin: '4px',
                                        padding: '16px',
                                        border: `1px solid grey`,
                                        borderRadius: 4,
                                    }}
                                    focusStyle={{
                                        outline: 'none',
                                        border: `1px solid grey`
                                    }}
                                />
                                {touched.otp && errors.otp && (
                                    <Form.Text className="text-danger">{errors.otp}</Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="password-reset">
                                <Form.Label>Create New Password</Form.Label>
                                <Form.Control
                                    className={touched.password && errors.password ? 'error' : ''}
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter password"
                                />
                                {touched.password && errors.password && (
                                    <Form.Text className="text-danger">{errors.password}</Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="confirm-password-reset">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    className={touched.confirmPassword && errors.confirmPassword ? 'error' : ''}
                                    type="password"
                                    value={values.confirmPassword}
                                    name="confirmPassword"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter confirm password"
                                />
                                {touched.confirmPassword && errors.confirmPassword && (
                                    <Form.Text className="text-danger">{errors.confirmPassword}</Form.Text>
                                )}
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={isSubmittingForm}>
                                {isSubmittingForm ? <Spinner animation="border" size="sm" /> : "Reset Password"}
                            </Button>
                            {errors.submit && <Form.Text className="text-danger">{errors.submit}</Form.Text>}
                            <div>
                                <span>Did not receive the email? Check spam folder or</span>
                                <Button onClick={() => handleResendCode()} variant="link" disabled={resendCodeLoading}>
                                    {resendCodeLoading ? <Spinner animation="border" size="sm" /> : "Resend code"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card>
        </div>
    );
};

export default AWSCognitoAuthResetPassword;
