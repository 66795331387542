export function applyCanvasRenderFix() {
  // When downloading the PDF on firefox got an error stating that canvas width was 0
  // and this function is the fix for that

  // Store the original createPattern method
  const originalCreatePattern = CanvasRenderingContext2D.prototype.createPattern;

  CanvasRenderingContext2D.prototype.createPattern = function(image, repetition) {
    // Check if the image is a canvas and has zero width or height
    if (image instanceof HTMLCanvasElement && (image.width === 0 || image.height === 0)) {
      console.warn('Attempted to create pattern from canvas with zero width or height');
      return null;
    }
    // Call the original method if the check passes
    return originalCreatePattern.call(this, image, repetition);
  };

  // Function to ensure canvas has non-zero dimensions
  function ensureCanvasSize(canvas: HTMLCanvasElement) {
    if (canvas.width === 0 || canvas.height === 0) {
      // Set width and height to 1 if they're 0
      canvas.width = canvas.width || 1;
      canvas.height = canvas.height || 1;
    }
  }

  // Function to fix all canvas elements in the document
  function fixCanvasElements() {
    const canvases = document.getElementsByTagName('canvas');
    Array.from(canvases).forEach(ensureCanvasSize);
  }

  // Apply fix on load and periodically
  window.addEventListener('load', fixCanvasElements);
  setInterval(fixCanvasElements, 1000);

  // MutationObserver to handle dynamically added canvases
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLCanvasElement) {
            ensureCanvasSize(node);
          }
        });
      }
    });
  });

  // Start observing the document body for changes
  observer.observe(document.body, { childList: true, subtree: true });
}
