import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import useIncidentContext from "../../lib/hooks/useIncidentContext";

import ColumnInputField from "components/templatePage1/columnInputField";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function ReportingOfficer() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [reportingOfficerData, setReportingOfficerData] = useState([
    { text: "rank", value: "" },
    { text: "lastName", value: "" },
    { text: "cmd", value: "" },
    { text: "taxRegNo", value: "" },
    { text: "date", value: "" },
  ]);
  const [supervisor, setSupervisor] = useState([
    { text: "telephone", value: "" },
    { text: "cmd", value: "" },
    { text: "taxRegNo", value: "" },
    { text: "date", value: "" },
  ]);

  useEffect(() => {
    if (generatedResult) {
      const data = generatedResult?.InfoReportingPoliceOfficer;
      setReportingOfficerData([
        { text: "rank", value: data?.rank == "NA" ? " " : data?.rank },
        { text: "lastName", value: data?.lastName == "NA" ? " " : data?.lastName },
        { text: "cmd", value: data?.command == "NA" ? " " : data?.command },
        { text: "taxRegNo", value: data?.taxRegistrationNo == "NA" ? " " : data?.taxRegistrationNo },
        { text: "date", value: data?.date == "NA" ? " " : data?.date },
      ]);
    }
  }, [generatedResult]);

  const inputHandlerOfficer = (index: number, value: any) => {
    setReportingOfficerData((prevList) => {
      const updatedList = [...reportingOfficerData];
      updatedList[index].value = value;
      return updatedList;
    });
  };
  const inputHandlerSupervisor = (index: number, value: any) => {
    setSupervisor((prevList) => {
      const updatedList = [...supervisor];
      updatedList[index].value = value;
      return updatedList;
    });
  };

  return (
    <div className="p-0 w-100">
      <div className="p-0 m-0 w-100 d-flex flex-row justify-content-center border-top border-bottom border-dark bg-secondary bg-opacity-75">
        Information on Reporting Officer(s)
      </div>

      <div className="w-100 d-flex flex-row border-bottom border-2 border-dark">
        <div className="p-0 col-4 d-flex flex-row">
          <div className="h-100 col-3 border-end border-2 border-dark">
            <ColumnInputField inputFieldText="Rank" userInputHandler={inputHandlerOfficer} index={0} defaultValue={reportingOfficerData[0].value} />
          </div>
          <div className="p-0 h-100 col-9 border-end border-2 border-dark">
            <ColumnInputField
              inputFieldText="Last Name (typed)"
              userInputHandler={inputHandlerOfficer}
              index={1}
              defaultValue={reportingOfficerData[1].value}
            />
          </div>
        </div>
        <div className="p-0 col-4 border-end border-2 border-dark">
          <InputGroup size="sm" className="h-100 bg-light">
            <InputGroup.Text className="h-25 w-100 text-dark">Signature</InputGroup.Text>
            <Form.Control aria-label="Small" aria-describedby="inputGroup-sizing-sm" className="w-25" disabled={!isEditing} />
          </InputGroup>
        </div>
        <div className="p-0 col-4 d-flex flex-row">
          <div className="h-100 col-3 border-end border-2 border-dark">
            <ColumnInputField inputFieldText="Cmd." userInputHandler={inputHandlerOfficer} index={2} defaultValue={reportingOfficerData[2].value} />
          </div>
          <div className="p-0 h-100 col-5 border-end border-2 border-dark">
            <ColumnInputField
              inputFieldText="Tax Reg. No."
              userInputHandler={inputHandlerOfficer}
              index={3}
              defaultValue={reportingOfficerData[3].value}
            />
          </div>
          <div className="p-0 h-100 col-4">
            <ColumnInputField inputFieldText="Date" userInputHandler={inputHandlerOfficer} index={4} defaultValue={reportingOfficerData[4].value} />
          </div>
        </div>
      </div>
      <div className="w-100 d-flex flex-row border-bottom border-dark">
        <div className="p-0 col-5 d-flex flex-row">
          <div className={classNames(css(styles.normalFont), "col-3 border-end border-2 border-dark")}>Supervisor If Appropriate</div>
          <div className="p-0 h-100 col-9 border-end border-2 border-dark">
            <InputGroup size="sm" className="h-100 w-100 d-flex flex-column bg-light">
              <InputGroup.Text className="h-25 w-100 text-dark">Signature</InputGroup.Text>
              <Form.Control aria-label="Small" aria-describedby="inputGroup-sizing-sm" className="w-100 h-75" disabled={!isEditing} />
            </InputGroup>
          </div>
        </div>
        <div className="p-0 col-3 d-flex flex-row">
          <div className="col-3 border-end border-2 border-dark">
            <ColumnInputField inputFieldText="Cmd." userInputHandler={inputHandlerSupervisor} index={1} defaultValue={supervisor[1].value} />
          </div>

          <div className="p-0 col-9 border-end border-2 border-dark">
            <ColumnInputField inputFieldText="Tax Reg. No." userInputHandler={inputHandlerSupervisor} index={2} defaultValue={supervisor[2].value} />
          </div>
        </div>
        <div className="p-0 col-4 d-flex flex-row border">
          <div className="col-8 border-end border-2 border-dark">
            <ColumnInputField inputFieldText="Telephone No." userInputHandler={inputHandlerSupervisor} index={0} defaultValue={supervisor[0].value} />
          </div>

          <div className="p-0 col-4 border">
            <ColumnInputField inputFieldText="Date" userInputHandler={inputHandlerSupervisor} index={3} defaultValue={supervisor[3].value} />
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.9rem",
  },
});
export default ReportingOfficer;
