import React from 'react';

import { BsClipboard2Plus } from 'react-icons/bs';
import { For } from "tsx-control-statements/components";
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import LOGO from 'assets/images/transparent-horizontal-logo.svg';
import Offcanvas from "react-bootstrap/Offcanvas";
import { Nav, NavLink } from 'react-bootstrap';
import { RiFileHistoryLine } from 'react-icons/ri';

import { copyRights, UserSidebarItems } from "constants/userSideBarItem";
import {SidebarItems} from "constants/sidebarItems";
import useSharedContext from "lib/hooks/useSharedContext";

const UserSidebar: React.FC = () => {
    const { isSidebarVisible, setIsSidebarVisible, handleSidebar } = useSharedContext();
    let template ={
        icon: '',
        menu: '',
        link: ''
    }

const getIconComponent = (iconName: string) => {
    switch (iconName) {
        case 'BsClipboard2Plus':
            return <BsClipboard2Plus className="me-2" size={30} />;
        case 'RiFileHistoryLine':
            return <RiFileHistoryLine className="me-2" size={30} />;
        default:
            return null;
    }
};

return (
    <>
        <Offcanvas
            className={classNames(
            css(styles.sidebar),
            "d-flex flex-column flex-shrink-0 text-white bg-dark"
            )}
            show={isSidebarVisible}
            onHide={handleSidebar}
        >
            <Offcanvas.Header closeButton closeVariant="white">
                <a
                    href={SidebarItems[0].link}
                    className="mt-3 me-3"
                >
                    <img src={LOGO} alt="Logo" className="w-100 h-100 me-2"/>
                </a>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-0">
                <hr />
                    <div className="d-flex flex-column justify-content-between">
                        <div>
                            <Nav className="nav nav-pills mb-auto flex-column">
                                <For each="template" of={UserSidebarItems}>
                                <NavLink href={template.link} className="text-white">
                                    {template.menu}
                                </NavLink>
                                </For>
                            </Nav>
                        </div>
                    </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>
    );
};

const styles = StyleSheet.create({
    sidebar: {
        width: '5rem',
        minWidth: '12rem',
    },
    copyRights: {
        fontSize: '0.75rem',
    },
});

export default UserSidebar;
