import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import DomesticInputBox from "components/domesticTemplate/domesticTemplatePage1/domesticInputBox";
import useIncidentContext from "lib/hooks/useIncidentContext";
import InputField from "components/domesticTemplate/domesticTemplatePage1/inputField";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticOfficerSection() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [suspectChildAbuse, setSuspectChildAbuse] = useState<boolean>();
  const [dirGiven, setDirGiven] = useState<boolean>();
  const [dirNotGivenReason, setDirNotGivenReason] = useState("");
  const [victimRightsGiven, setVictimRightsGiven] = useState<boolean>();
  const [victimRightsNotGivenReason, setVictimRightsNotGivenReason] = useState("");
  const [signatures, setSignatures] = useState("");
  const [officersDetails, setOfficersDetails] = useState({
    supervisor: "",
    reportingOfficer: "",
  });

  useEffect(() => {
    if (generatedResult && generatedResult["officer filling"]) {
      const domesticOfficer = generatedResult["officer filling"] || "";
      setSuspectChildAbuse(domesticOfficer["reasonableCase"] == "Yes");
      setDirGiven(domesticOfficer["DIR_Given"] == "Yes");
      setDirNotGivenReason(domesticOfficer["DIR_not_given_reason"]);
      setVictimRightsGiven(domesticOfficer["Victim_right_given"] == "Yes");
      setVictimRightsNotGivenReason(domesticOfficer["Victim_Right_Notice_not _given_reason"]);
    }
  }, [generatedResult]);

  const handleInputChange = (field: any, value: any) => {
    setOfficersDetails({
      ...officersDetails,
      [field]: value,
    });
  };

  return (
    <div className="d-flex p-0 w-100  flex-fill">
      <div className="border-start border-end border-top border-black border-3 w-100">
        <div className="d-flex flex-column border-end border-bottom border-black">
          <div className={classNames(css(styles.normalFont), "d-flex align-content-start flex-wrap p-0")}>
            <div className="d-flex">
              Is there reasonable cause to suspect a child may be the victim of abuse, neglect, maltreatment or endangerment?
            </div>
            <div className="d-flex pe-1 h-50">
              <Form.Check
                type="checkbox"
                label="Yes"
                className="m-0"
                checked={suspectChildAbuse == true}
                onChange={() => setSuspectChildAbuse(true)}
                disabled={!isEditing}
              />
            </div>
            <div className="d-flex pe-1">
              <Form.Check
                type="checkbox"
                label="No"
                className="m-0"
                checked={suspectChildAbuse == false}
                onChange={() => setSuspectChildAbuse(false)}
                disabled={!isEditing}
              />
            </div>
          </div>
          <div className={classNames(css(styles.normalFont), "d-flex")}>
            If Yes, the Officer must contact the NYS Child Abuse Hotline Registry # 1-800-635-1522.
          </div>
        </div>

        <div className="border-bottom border-black d-flex flex-row">
          <div className={classNames(css(styles.normalFont), "d-flex flex-column col-6 border-end border-black")}>
            <div className="d-flex flex-row justify-content-start flex-wrap p-0">
              <div className="d-flex">Was DIR given to the Victim at the scene?</div>
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  className="m-0"
                  checked={dirGiven == true}
                  onChange={() => setDirGiven(true)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="No"
                  className="m-0"
                  checked={dirGiven == false}
                  onChange={() => setDirGiven(false)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex">if NO, Why</div>
            </div>
            <div className="d-flex flex-fill">
              <DomesticInputBox defaultValue={dirNotGivenReason} userInputHandler={setDirNotGivenReason} />
            </div>
          </div>

          <div className={classNames(css(styles.normalFont), "d-flex flex-column col-6 border-end border-black")}>
            <div className="d-flex flex-row justify-content-start flex-wrap p-0">
              <div className="d-flex">Was Victim Rights Notice given to the Victim?</div>
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  className="m-0"
                  checked={victimRightsGiven == true}
                  onChange={() => setVictimRightsGiven(true)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="No"
                  className="m-0"
                  checked={victimRightsGiven == false}
                  onChange={() => setVictimRightsGiven(false)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex">if NO, Why</div>
            </div>
            <div className="d-flex flex-fill">
              <DomesticInputBox defaultValue={victimRightsNotGivenReason} userInputHandler={setVictimRightsNotGivenReason} />
            </div>
          </div>
        </div>

        <div className="w-100 text-dark p-1 d-flex align-content-start flex-wrap border-bottom border-black">
          <InputGroup size="sm" className="h-100 d-flex align-items-center flex-row">
            <InputGroup className="text-dark border-0 rounded-0 d-flex flex-fill justify-content-start w-25">Signatures:</InputGroup>

            <DomesticInputBox defaultValue={signatures} userInputHandler={setSignatures} />
          </InputGroup>
        </div>
        <div className="border-bottom border-black d-flex flex-row">
          <div className="d-flex flex-row col-6">
            <InputField
              inputFieldText="Reporting Officer (Print and Sign include Rank and ID#)"
              defaultValue={officersDetails.reportingOfficer}
              userInputHandler={handleInputChange}
              field={"reportingOfficer"}
            />
          </div>
          <div className="d-flex flex-row col-6">
            <InputField
              inputFieldText="Supervisor (Print and Sign include Rank and ID#)"
              defaultValue={officersDetails.supervisor}
              userInputHandler={handleInputChange}
              field={"supervisor"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
});
export default DomesticOfficerSection;
