import React from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import AbstractSection from "components/templatePage2/abstractSection";
import CodeSection from "components/templatePage2/codeSection";

function TemplatePage2() {
  return (
    <div className="mt-5 w-100 d-flex justify-content-center bg-light">
      <div className={classNames(css(styles.pageWrapper), "d-flex flex-column justify-content-center p-0")}>
        <Row className="text-dark border-bottom p-0 border-4 border-dark">
          <Col className="p-0 pb-3 d-flex justify-content-center">
            <div className="mt-2 pt-1 pb-1 h-auto w-25 border d-flex justify-content-center align-items-center"> Page2 of 2</div>
          </Col>
        </Row>

        <Row className=" border-bottom border-dark">
          <CodeSection />
        </Row>
        <Row className="text-dark p-2">
          <AbstractSection />
        </Row>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  pageWrapper: {
    width: "90%",
  },
});
export default TemplatePage2;
