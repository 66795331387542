import React from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { Spinner } from "react-bootstrap";

function LoadingPage() {
  return (
    <div
      className={classNames(
        css(styles.container),
        "d-flex justify-content-center align-items-center"
      )}
    >
      <Spinner animation="border" role="status" className={css(styles.spinner)}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <h1 className={classNames(css(styles.loadingText), "fill-black")}>
        Loading
      </h1>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
    height: "80vh",
    minHeight: "300px",
    width: "50vw",
  },
  loadingText: {
    fontSize: "3vw",
    alignItems: "center",
    marginLeft: "5vw",
    minHeight: "50px",
  },
  spinner: {
    marginLeft: "5vw",
    width: "5vw",
    height: "5vw",
    marginBottom: "7vh",
    borderWidth: "0.5vw",
    animationDuration: "1.5s",
  },
});

export default LoadingPage;
