import React from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { For } from "tsx-control-statements/components";
import ListGroup from "react-bootstrap/ListGroup";

interface Props {
  titleOfSection?: string;
  Description?: string;
  Details?: string;
  listOfDetails?: string[];
}
function SectionsDetails(prop: Props) {
  return (
    <div className="p-0 w-100 bg-light">
      <div className="d-flex flex-column w-100 p-0 m-0">
        <p>
          <span className={classNames(css(styles.normalFont), "fw-bold")}>{prop.titleOfSection}</span>
          <If condition={prop.Description} className={classNames(css(styles.paragraphFont))}>
            {prop.Description}
          </If>
        </p>
        <p className={classNames(css(styles.paragraphFont))}>{prop.Details}</p>

        <If condition={prop.listOfDetails !== undefined}>
          <ListGroup as="ol" numbered>
            <For
              each="listItem"
              of={prop.listOfDetails || []}
              body={(listItem, index) => (
                <ListGroup.Item key={`listItem_${index}`} as="li" className={classNames(css(styles.paragraphFont), "border-0 bg-light text-dark")}>
                  {listItem}
                </ListGroup.Item>
              )}
            />
          </ListGroup>
        </If>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  pageWrapper: {
    width: "90%",
  },
  paragraphFont: {
    fontSize: "0.85rem",
  },
  normalFont: {
    fontSize: "0.9rem",
  },
});
export default SectionsDetails;
