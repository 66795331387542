import React from 'react';

import AuthenticationView from "components/auth/AuthenticationView";
import AWSCognitoRegister from "components/auth/Registration/AWSCognitoRegister";

const RegistrationPage: React.FC = () => {
    return (
        <div>
            <AuthenticationView dynamicComponent={AWSCognitoRegister} />
        </div>
    );
};

export default RegistrationPage;
