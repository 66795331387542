const AUTH = {
  LOGIN : '/login',
  VALIDATE_TOKEN : '/validateToken',
  INITIATE_PASSWORD_RESET : '/initiate_password_reset',
  CONFIRM_PASSWORD_RESET : '/confirm_password_reset',
  RESEND_VERIFICATION_CODE : '/resend_verification_code',
  CONFIRM_EMAIL : '/confirmEmail',
  SIGNUP : 'signup',
};

const INCIDENT_ACTIONS = {
  NEW_INCIDENT : '/incident/new_incident',
  SAVE_INCIDENT : '/incident/save_incident',
  CLEAR_AUDIO_SAVED : '/incident/clear_audio_saved',
};


export {
  AUTH,
  INCIDENT_ACTIONS
};
