import { useContext } from 'react';

import AWSCognitoContext from 'contexts/AWSCognitoContext';

const useAuth = () => {
  const context = useContext(AWSCognitoContext);

  if (!context) {
    console.error('Auth context is not available. Make sure to wrap your components with AWSCognitoProvider.');
    return null;
  }

  return context;
};

export default useAuth;
