import React,{useState} from "react";

import { Button, Card, Row } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";

import Colors from "constants/colors";
import Column from "components/viewComponents/Column";
import ViewCaseModal from "components/viewComponents/ViewCaseModal";

interface Case {
  title: string;
  victim?: string;
  location: string;
  reportedDate: string;
  dependent?: string;
  addedDate: string;
  reportedOfficer: string;
  reportedOffice: string;
  caseDescription: string;
  audioUrl: string;
  pdfUrl: string;
}

interface Props {
  caseData: Case;
}

function CaseCard({ caseData }: Props) {
  const { title, victim, location, reportedDate, dependent, addedDate, reportedOffice, reportedOfficer, caseDescription, audioUrl, pdfUrl } = caseData;
  const [showModal, setShowModal] = useState(false);

  const viewButtonClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Card className="border-0">
      <div
        className={classNames(
          css(styles.container),
          "px-3 py-1 d-flex flex-row justify-content-space-between"
        )}
      >
        <Card.Body>
          <Card.Title className={classNames(css(styles.header), "fw-bold")}>
            {title}
          </Card.Title>
          <div className={css(styles.body)}>
            <Row>
              {victim && <Column value={victim} />}
              {dependent && <Column value={dependent} />}
            </Row>
            <Row>
              <Column value={location} />
            </Row>
            <Row>
              <Column value={reportedDate} />
            </Row>
          </div>
        </Card.Body>
        <div className="w-25 d-flex align-items-center justify-content-center">
          <Button className={"w-50"} onClick={viewButtonClick}>
            View
          </Button>
        </div>
      </div>
        <ViewCaseModal
          show={showModal}
          onHide={closeModal}
          caseData={{
            title,
            victim: victim ?? "N/A",
            location,
            reportedDate,
            addedDate,
            reportedOfficer,
            reportedOffice,
            caseDescription,
            audioUrl,
            pdfUrl,
            dependent: dependent ?? "N/A",
          }}
        />
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    background: Colors.lightBlue,
  },
  header: {
    color: Colors.black,
    fontSize: "1.1rem",
  },
  body: {
    color: Colors.black,
  },
});

export default CaseCard;
