import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import { css, StyleSheet } from 'aphrodite';
import { For } from 'tsx-control-statements/components';
import Row from 'react-bootstrap/Row';
import useIncidentContext from '../../lib/hooks/useIncidentContext';

import NormalInput from 'components/templatePage1/normalInput';

const singlePerson = {
  line: 1,
  value: {
    lastName: '',
    firstName: '',
    mi: '',
    aliases: '',
    sex: '',
    month: '',
    day: '',
    year: '',
    ethic: '',
    relation: '',
    PorV: '',
  },
};

function FirstSection() {
  const { generatedResult } = useIncidentContext();

  const [listofPerson, setListofPerson] = useState([singlePerson]);

  useEffect(() => {
    if (generatedResult?.household) {
      const updatedListofPerson = generatedResult?.household.map((person: any, index: any) => ({
        line: index + 1,
        value: {
          lastName: person?.lastName == 'NA' ? '' : person?.lastName,
          firstName: person?.firstName == 'NA' ? '' : person?.firstName,
          mi: person?.middleInitial == 'NA' ? '' : person?.middleInitial,
          aliases: person?.aliases == 'NA' ? '' : person?.aliases,
          sex: person?.sex == 'Male' ? ' M' : ' F',
          month: person?.birthDate?.month=='NA'?'':person?.birthDate?.month,
          day: person?.birthDate?.day=='NA'?'':person?.birthDate?.day,
          year: person?.birthDate?.year=='NA'?'':person?.birthDate?.year,
          ethic: getEthnicity(person?.ethnicity),
          relation: getRelation(person?.relationshipToVictim, person?.suspicions, person?.isChild),
          PorV: getPersonType(person?.perpetratorOrVictim)
        }
      }));
      setListofPerson(updatedListofPerson);
    }
  }, [generatedResult]);

  const getEthnicity = (ethnicity: string) => {
    switch (ethnicity.trim().toLowerCase().replace(/\s/g,"").replace(/-/g,"")) {
      case 'blackorafricanamerican':
      case 'black':
      case 'africanamerican':
        return 'AA';
      case 'alaskannative':
        return 'AL';
      case 'asian':
        return 'AS';
      case 'nativeamerican':
        return 'NA';
      case 'nativehawaiianpacificislander':
      case 'nativehawaiian':
      case 'pacificislander':
        return 'PI';
      case 'white':
        return 'WH';
      case 'other':
        return 'XX';
      case 'unknown':
        return 'UNK';
      default:
        return '';
    }
  };

  const getRelation = (relationshipToVictim: string, suspicions: string, isChild?: boolean) => {
    if (isChild) {
      switch (suspicions.trim().toLowerCase().replace(/\s/g,"")) {
        case 'gotabused':
          return 'AB';
        case 'gotmaltreated':
          return 'MA';
        case 'none':
          return 'NO';
        case 'unknown':
          return 'UN';
        default:
          return '';
      }
    } 
    switch (relationshipToVictim.trim().toLowerCase().replace(/\s/g,"")) {
      case 'parent':
        return 'P';
      case 'parentalsubstitute':
        return 'PS';
      case 'fosterparent':
        return 'FP';
      case 'grandparent':
        return 'GP';
      case 'relative':
        return 'R';
      case 'norelationship':
        return 'N';
      case 'unknown':
        return 'UN';
      default:
        return '';
    };
  };

  const getPersonType = (type: string) => {
    switch (type.trim().toLowerCase()) {
      case "victim":
        return "V";
      case "perpetrator":
          return "P";
      default:
        return ' ';
    }
  };

  const inputHandler = (index: number, text: string, value: any) => {
    setListofPerson((prevList) => {
      const updatedList = [...prevList];
      if (text == 'lastName') {
        updatedList[index].value.lastName = value;
      }
      if (text == 'firstName') {
        updatedList[index].value.firstName = value;
      }
      if (text == 'mi') {
        updatedList[index].value.mi = value;
      }
      if (text == 'aliases') {
        updatedList[index].value.aliases = value;
      }
      if (text == 'sex') {
        updatedList[index].value.sex = value;
      }
      if (text == 'month') {
        updatedList[index].value.month = value;
      }
      if (text == 'day') {
        updatedList[index].value.day = value;
      }
      if (text == 'year') {
        updatedList[index].value.year = value;
      }
      if (text == 'ethic') {
        updatedList[index].value.ethic = value;
      }
      if (text == 'relation') {
        updatedList[index].value.relation = value;
      }
      if (text == 'PorV') {
        updatedList[index].value.PorV = value;
      }
      return updatedList;
    });
  };

  return (
    <>
      <Row className={classNames(css(styles.normalFont), 'pe-0 h-100 w-100 border-bottom border-2 border-dark text-dark')}>
        <div className="pe-0 col-7 d-flex flex-column">
          <div className="h-50">List all children in household, adults responsible for household, and alleged perpetrator(s).</div>
          <div className="p-0 h-50 d-flex flex-row">
            <div className="h-100 col-1 d-flex align-items-end border-end border-2 border-dark">Line NO.</div>
            <div className="m-0 p-0 h-100 col d-flex align-items-end border-start">Last Name</div>
            <div className="m-0 p-0 h-100 col d-flex align-items-end">First Name</div>
            <div className="ms-1 me-0 col d-flex flex-row justify-content-between">
              <div className="m-0 col-3 d-flex align-items-end">M.I.</div>
              <div className="col-1"></div>
              <div className="m-0 p-0 col-7 d-flex align-items-end">Aliases</div>
            </div>
          </div>
        </div>
        <div className="p-0 col-5 d-flex flex-row">
          <div className="p-0 col-7 d-flex flex-row">
            <div className="p-0 col-2 border-end border-start border-2 border-dark">Sex</div>
            <div className="p-0 col-6 d-flex flex-column border-end border-2 border-dark">
              <div className="h-50 d-flex">Birthdate Mo. Day Yr.</div>
              <div className="h-50 d-flex flex-row">
                <Col className="d-flex align-items-end border-end border-1 border-dark">MO.</Col>
                <Col className="d-flex align-items-end border-end border-1 border-dark">Day</Col>
                <Col className="d-flex align-items-end">Yr</Col>
              </div>
            </div>
            <div className="col-4 border-end border-2 border-dark">*Ethic code (see page 2)</div>
          </div>
          <div className="p-0 col-5 d-flex flex-row">
            <div className="h-100 col-6 border-end border-2 border-dark">** Susp. or Relation Code (see page 2)</div>
            <div className="h-100 col-6"> Mark P for Perpetrator V for Victim</div>
          </div>
        </div>
      </Row>

      <For
        each="number"
        of={listofPerson}
        body={(number, index) => (
          <Row className="pe-0 h-100 w-100 text-dark">
            <div className="pe-0 me-0 col-7">
              <div className="d-flex border-bottom border-2 border-dark">
                <Col className="p-0 col-1 border-end border-2 border-dark">{listofPerson[index].line}</Col>
                <Col className="p-0 border text-dark">
                  <NormalInput userInputHandler={inputHandler} index={index} text="lastName" defaultValue={listofPerson[index].value.lastName} />
                </Col>
                <Col className="p-0 border">
                  <NormalInput userInputHandler={inputHandler} index={index} text="firstName" defaultValue={listofPerson[index].value.firstName} />
                </Col>

                <Col className="ms-1 me-0 pe-0 border">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="h-100 col-3 border">
                      <NormalInput userInputHandler={inputHandler} index={index} text="mi" defaultValue={listofPerson[index].value.mi} />
                    </div>
                    <div className="m-0 p-0 h-100 col-1 border-end"></div>
                    <div className="m-0 p-0 col-7 border-start border-top border-bottom">
                      <NormalInput userInputHandler={inputHandler} index={index} text="aliases" defaultValue={listofPerson[index].value.aliases} />
                    </div>
                  </div>
                </Col>
              </div>
            </div>
            <div className="p-0 col-5 d-flex border-bottom border-2 border-dark">
              <div className="col-7 d-flex">
                <div className="p-0 col-2 border-end border-start border-2 border-dark">
                  <NormalInput userInputHandler={inputHandler} index={index} text="sex" defaultValue={listofPerson[index].value.sex} />
                </div>
                <div className="p-0 col-6 d-flex flex-row">
                  <Col className="border-end border-2 border-dark">
                    <NormalInput userInputHandler={inputHandler} index={index} text="month" defaultValue={listofPerson[index].value.month} />
                  </Col>
                  <Col className="p-0 border-end border-2 border-dark">
                    <NormalInput userInputHandler={inputHandler} index={index} text="day" defaultValue={listofPerson[index].value.day} />
                  </Col>
                  <Col className="p-0 border-end border-2 border-dark">
                    <NormalInput userInputHandler={inputHandler} index={index} text="year" defaultValue={listofPerson[index].value.year} />
                  </Col>
                </div>
                <div className="p-0 col-4 border-end border-2 border-dark">
                  <NormalInput userInputHandler={inputHandler} index={index} text="ethic" defaultValue={listofPerson[index].value.ethic} />
                </div>
              </div>
              <div className="p-0 col-5 d-flex">
                <Col className="p-0 border-end border-2 border-dark">
                  <NormalInput userInputHandler={inputHandler} index={index} text="relation" defaultValue={listofPerson[index].value.relation} />
                </Col>
                <Col className="p-0">
                  <NormalInput userInputHandler={inputHandler} index={index} text="PorV" defaultValue={listofPerson[index].value.PorV} />
                </Col>
              </div>
            </div>
          </Row>
        )}
      />
    </>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: '0.8rem',
  },
});
export default FirstSection;
