import useAxiosPost from "services/middleware/useAxiosPost";

import Swal from "sweetalert2";

import {INCIDENT_ACTIONS} from "constants/api";
import useAxiosGet from "services/middleware/useAxiosGet";


interface CaseData {
    officer?: string;
    incident?: string;
    openAiResponse?: string;
}

function useIncidentActions() {
    const { post:saveIncidentData, error:saveIncidentError } = useAxiosPost(INCIDENT_ACTIONS.SAVE_INCIDENT);
    const { data: setNewIncidentInputResponse, error: setNewIncidentInputError, refetch: setNewIncidentUpload} = useAxiosGet(INCIDENT_ACTIONS.NEW_INCIDENT)
    const { data: clearAudioSavedResponse , error: clearAudioSavedError, refetch: clearAudioSavedRecall} = useAxiosGet(INCIDENT_ACTIONS.CLEAR_AUDIO_SAVED)

    const saveIncident = async (caseData: CaseData) => {
        return saveIncidentData(caseData);
    };

    const setNewIncident = async () => {
        return setNewIncidentUpload();
    }

    const clearIncidentAudioSaved = async () => {
        return clearAudioSavedRecall();
    }

    if (saveIncidentError || setNewIncidentInputError || clearAudioSavedError) {
        const errorMessage: any = saveIncidentError;
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
        });
    }
    return { saveIncident, setNewIncident, clearIncidentAudioSaved };
}

export default useIncidentActions;