import React, { useState } from 'react';

import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';

import { AuthenticationRoutes } from 'constants/generalRoutes';
import ResetPassword from "components/auth/ResetPassword/ResetPassword";
import useAuth from "hooks/useAuth";

interface FormValues {
    email: string;
    submit: any;
}

const ForgotPassword: React.FC = () => {

    const { forgotPassword } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false); // State to track success

    const handleSubmit = async (
        values: FormValues,
        { setErrors }: FormikHelpers<FormValues>
    ) => {
        setIsLoading(true);
        try {
            localStorage.clear();
            localStorage.setItem('email', values.email);
            await forgotPassword(values.email);
            setSuccess(true); // Set success to true upon successful response
        } catch (err) {
            // @ts-ignore
            setErrors({ submit: err });
        }
        setIsLoading(false);
    };

    if (success) {
        return <ResetPassword />;
    }

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Formik
                initialValues={{
                    email: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="email-forgot">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email address"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && errors.email && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            Send Verification Code
                        </Button><br/>
                        {errors.submit && (
                            <Form.Text className="text-danger mt-2">{errors.submit}</Form.Text>
                        )}
                        <div>
                            <span>Already Have An Account? SignIn</span>
                            <Button
                                className='px-1 pb-2'
                                variant='link'
                                href={AuthenticationRoutes.Login}
                                >
                                here
                        </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ForgotPassword;
