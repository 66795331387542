import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";

import useIncidentContext from "lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticIncidentNarrative() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const contentEditableRef = useRef(null);
  const [isDirRepositoryChecked, setIsDirRepositoryChecked] = useState(false);
  const [isOrderOfProtectionRegistryChecked, setIsOrderOfProtectionRegistryChecked] = useState(false);
  const [isOrderOfProtectionInEffect, setIsOrderOfProtectionInEffect] = useState(false);

  const [incidentDescription, setIncidentDescription] = useState("");
  const [isRefrainChecked, setIsRefrainChecked] = useState(false);
  const [isStayAwayChecked, setIsStayAwayChecked] = useState(false);

  useEffect(() => {
    if (generatedResult && generatedResult["Incident Narrative"]) {
      const incidentNarrative = generatedResult["Incident Narrative"];

      setIsDirRepositoryChecked(incidentNarrative["DIR Repository checked"] === "Yes");
      setIsOrderOfProtectionRegistryChecked(incidentNarrative["Order of Protection Registry checked"] === "Yes");
      setIncidentDescription(incidentNarrative["Briefly describe the circumstances of this incident"]);

      const orderOfProtectionInEffect = incidentNarrative["Order of Protection in effect"];
      setIsOrderOfProtectionInEffect(orderOfProtectionInEffect === "Yes");

      setIsRefrainChecked(incidentNarrative["Refrain"]);
      setIsStayAwayChecked(incidentNarrative["Stay Away"]);
    }
  }, [generatedResult]);

  const handleDirRepositoryChange = (e: any) => {
    const isChecked = e.target.checked;
    setIsDirRepositoryChecked(isChecked);
  };
  const handleRefrainChange = (e: any) => {
    const isChecked = e.target.checked;
    setIsRefrainChecked(isChecked);
  };

  const handleStayAwayChange = (e: any) => {
    const isChecked = e.target.checked;
    setIsStayAwayChecked(isChecked);
  };
  const handleOrderOfProtectionRegistryChange = (e: any) => {
    const isChecked = e.target.checked;
    setIsOrderOfProtectionRegistryChecked(isChecked);
  };

  const handleOrderOfProtectionInEffectChange = (e: any) => {
    const isChecked = e.target.checked;
    setIsOrderOfProtectionInEffect(isChecked);
  };

  const handleIncidentDescriptionChange = (e: any) => {
    const description = e.target.value;
    setIncidentDescription(description);
  };

  const handleUserText = (input: any) => {
    setIncidentDescription(input);
  };
  const handleInput = (e: any) => {
    handleUserText(e.currentTarget.innerHTML);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    insertTextAtCursor(text);
  };

  const insertTextAtCursor = (text: any) => {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }, [incidentDescription]);
  return (
    <div className="d-flex flex-column w-100 border-start border-end border-top border-black border-3 p-0">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span className="no-wrap">Incident Narrative</span>
      </div>
      <div className={classNames(css(styles.normalFont), "d-flex flex-column border-start border-end border-black border-3")}>
        <div className={classNames(css(styles.normalFont), "d-flex flex-column border-bottom border-black")}>
          <div className="d-flex">Briefly describe the circumstances of this incident:</div>

          <div
            className={classNames(css(styles.normalFont, styles.textAreaDiv), "border-0")}
            contentEditable={isEditing}
            ref={contentEditableRef}
            onInput={handleInput}
            onPaste={handlePaste}
            dangerouslySetInnerHTML={{ __html: incidentDescription }}
          ></div>
        </div>
        <div className="d-flex flex-row border-bottom border-black">
          <div
            className={classNames(
              css(styles.normalFont),
              "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-3"
            )}
          >
            <div className="d-flex">DIR Repository checked?</div>
            <div className="d-flex pe-1 h-50">
              <Form.Check
                type="checkbox"
                label="Yes"
                className="m-0"
                checked={isDirRepositoryChecked}
                onChange={handleDirRepositoryChange}
                disabled={!isEditing}
              />
            </div>
            <div className="d-flex pe-1">
              <Form.Check
                type="checkbox"
                label="No"
                className="m-0"
                checked={!isDirRepositoryChecked}
                onChange={(e) => setIsDirRepositoryChecked(!e.target.checked)}
                disabled={!isEditing}
              />
            </div>
          </div>

          <div
            className={classNames(
              css(styles.normalFont),
              "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-4"
            )}
          >
            <div className="d-flex">Order of Protection Registry checked?</div>
            <div className="d-flex pe-1 h-50">
              <Form.Check
                type="checkbox"
                label="Yes"
                className="m-0"
                checked={isOrderOfProtectionRegistryChecked}
                onChange={handleOrderOfProtectionRegistryChange}
                disabled={!isEditing}
              />
            </div>
            <div className="d-flex pe-1">
              <Form.Check
                type="checkbox"
                label="No"
                className="m-0"
                checked={!isOrderOfProtectionRegistryChecked}
                onChange={(e) => setIsOrderOfProtectionRegistryChecked(!e.target.checked)}
                disabled={!isEditing}
              />
            </div>
          </div>

          <div
            className={classNames(
              css(styles.normalFont),
              "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-5"
            )}
          >
            <div className="d-flex">Order of Protection in effect?</div>
            <div className="d-flex pe-1 h-50">
              <Form.Check
                type="checkbox"
                label="Yes"
                className="m-0"
                checked={isOrderOfProtectionInEffect}
                onChange={handleOrderOfProtectionInEffectChange}
                disabled={!isEditing}
              />
            </div>
            <div className="d-flex pe-1">
              <Form.Check
                type="checkbox"
                label="No"
                className="m-0"
                checked={!isOrderOfProtectionInEffect}
                onChange={(e) => setIsOrderOfProtectionInEffect(!e.target.checked)}
                disabled={!isEditing}
              />
            </div>
            <div className="d-flex pe-1">
              <Form.Check
                type="checkbox"
                label="Refrain"
                className="m-0"
                checked={isRefrainChecked}
                onChange={handleRefrainChange}
                disabled={!isEditing}
              />
            </div>
            <div className="d-flex pe-1">
              <Form.Check
                type="checkbox"
                label="Stay Away"
                className="m-0"
                checked={isStayAwayChecked}
                onChange={handleStayAwayChange}
                disabled={!isEditing}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
  textAreaDiv: {
    width: "100%",
    height: "auto",
    minHeight: "14em",
    resize: "none",
    padding: "0.5em",
    overflowY: "auto",
    borderWidth: 0,
  },
});
export default DomesticIncidentNarrative;
