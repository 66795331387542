import React, { useEffect, useState } from "react";

import classNames from "classnames";
import Col from "react-bootstrap/Col";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import useIncidentContext from "../../lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function ComplainmentDetails() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [registeredNumber, setRegisteredNumber] = useState({ Complaint: "", Arrest: "" });
  const [pct, setPct] = useState({ Complaint: "", Arrest: "" });
  const [selectedOption, setSelectedOption] = useState({ Complaint: false, Arrest: false });

  useEffect(() => {
    if (generatedResult) {
      setSelectedOption({
        Arrest: generatedResult?.arrest?.isArrest,
        Complaint: generatedResult?.complaint?.isComplaint,
      });
      setRegisteredNumber({
        Arrest: generatedResult?.arrest?.arrestNo == "NA" ? " " : generatedResult?.arrest?.arrestNo,
        Complaint: generatedResult?.complaint?.complaintNo == "NA" ? " " : generatedResult?.complaint?.complaintNo,
      });
      setPct({
        Arrest: generatedResult?.arrest?.arrestPrecinct == "NA" ? " " : generatedResult?.arrest?.arrestPrecinct,
        Complaint: generatedResult?.complaint?.precinctName == "NA" ? " " : generatedResult?.complaint?.precinctName,
      });
    }
  }, [generatedResult]);

  const handleNumberInput = (selectBoxName: string, value: string) => {
    setRegisteredNumber({ ...registeredNumber, [selectBoxName]: value });
  };
  const handlePct = (selectBoxName: string, value: string) => {
    setPct({ ...pct, [selectBoxName]: value });
  };
  const handleCheckboxChange = (selectBoxName: string, value: Boolean) => {
    setSelectedOption({ ...selectedOption, [selectBoxName]: value });
  };

  return (
    <div className="d-flex flex-row justify-content-center h-100 w-100 p-0 bg-light text-dark">
      <Col className="d-flex flex-row p-2 h-100">
        <div className="form-check w-auto d-flex flex-row">
          <span className="h-100 d-flex align-items-center me-1">Complaint:</span>
          <Form.Check
            inline
            label="Yes"
            type="checkbox"
            className="h-100 d-flex align-items-center"
            checked={selectedOption.Complaint}
            onChange={() => handleCheckboxChange("Complaint", !selectedOption.Complaint)}
            disabled={!isEditing}
          />
          <Form.Check
            inline
            label="No"
            type="checkbox"
            className="h-100 d-flex align-items-center"
            checked={!selectedOption.Complaint}
            onChange={() => handleCheckboxChange("Complaint", !selectedOption.Complaint)}
            disabled={!isEditing}
          />
        </div>
        <div className="w-50 text-dark p-1">
          <InputGroup size="sm" className="h-100 d-flex align-items-center">
            <InputGroup className={classNames(css(styles.numberInputBoxText), "text-dark border-0 rounded-0 d-flex justify-content-end")}>
              Number
            </InputGroup>
            <Form.Control
              className={classNames(css(styles.inputBoxColur), "border-0 rounded-0")}
              onChange={(e) => handleNumberInput("Complaint", e.target.value)}
              value={registeredNumber.Complaint}
              disabled={!isEditing}
            />
          </InputGroup>
        </div>

        <div className="w-25 text-dark p-1">
          <InputGroup size="sm" className="h-100 d-flex align-items-center">
            <InputGroup.Text className="text-dark w-50 border-0 rounded-0 d-flex justify-content-end">Pct.</InputGroup.Text>
            <Form.Control
              className={classNames(css(styles.inputBoxColur), "border-0 rounded-0")}
              onChange={(e) => handlePct("Complaint", e.target.value)}
              value={pct.Complaint}
              disabled={!isEditing}
            />
          </InputGroup>
        </div>
      </Col>
      <Col className="d-flex flex-row p-2 h-100">
        <div className="form-check w-auto d-flex flex-row">
          <span className="h-100 d-flex align-items-center me-1"> Arrest:</span>
          <Form.Check
            inline
            label="Yes"
            type="checkbox"
            className="h-100 d-flex align-items-center"
            checked={selectedOption.Arrest}
            onChange={() => handleCheckboxChange("Arrest", true)}
            disabled={!isEditing}
          />
          <Form.Check
            inline
            label="No"
            type="checkbox"
            className="h-100 d-flex align-items-center"
            checked={!selectedOption.Arrest}
            onChange={() => handleCheckboxChange("Arrest", false)}
            disabled={!isEditing}
          />
        </div>
        <div className="w-50 text-dark p-1">
          <InputGroup size="sm" className="h-100 d-flex align-items-center">
            <InputGroup className={classNames(css(styles.numberInputBoxText), "text-dark border-0 rounded-0 d-flex justify-content-end")}>
              Number
            </InputGroup>
            <Form.Control
              className={classNames(css(styles.inputBoxColur), "border-0 rounded-0")}
              onChange={(e) => handleNumberInput("Arrest", e.target.value)}
              value={registeredNumber.Arrest}
              disabled={!isEditing}
            />
          </InputGroup>
        </div>

        <div className="w-25 text-dark p-1">
          <InputGroup size="sm" className="h-100 d-flex align-items-center">
            <InputGroup.Text className="text-dark w-50 border-0 rounded-0 d-flex justify-content-end">Pct.</InputGroup.Text>
            <Form.Control
              className={classNames(css(styles.inputBoxColur), "border-0 rounded-0")}
              onChange={(e) => handlePct("Arrest", e.target.value)}
              value={pct.Arrest}
              disabled={!isEditing}
            />
          </InputGroup>
        </div>
      </Col>
    </div>
  );
}
const styles = StyleSheet.create({
  numberInputBoxText: {
    width: "30%",
  },
  inputBoxColur: {
    background: "#C7E4FF",
  },
});
export default ComplainmentDetails;
