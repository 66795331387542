import useCookie from 'hooks/useCookie';
import {CURRENT_USER} from 'constants/cookies';

function useCurrentUser() {
  const { getCookie } = useCookie();
  const userDataString = getCookie(CURRENT_USER);
  return userDataString ? JSON.parse(userDataString) : null;
}

export default useCurrentUser;
