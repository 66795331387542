import React, { useState } from "react";
import { SharedContext } from "../hooks/useSharedContext";

const SharedContextProvider = ({ children }: any) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  
  const handleSidebar = () =>{
    setIsSidebarVisible(!isSidebarVisible)
  }
  
  return (
    <SharedContext.Provider value={{ isSidebarVisible, setIsSidebarVisible, handleSidebar }}>
      {children}
    </SharedContext.Provider>
  );
};

export default SharedContextProvider;
