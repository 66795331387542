import React,{useState} from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { If } from 'tsx-control-statements/components';
import classNames from "classnames";
import Swal from 'sweetalert2';

import AudioPlayerModal from "components/viewComponents/AudioPlayerModal";

interface CaseData {
    title: string;
    victim: string;
    dependent: string;
    location: string;
    reportedDate: string;
    addedDate: string;
    reportedOfficer: string;
    reportedOffice: string;
    caseDescription: string;
    audioUrl: string;
    pdfUrl?: string;
}

interface ViewCaseModalProps {
    show: boolean;
    onHide: () => void;
    caseData: CaseData;
}

const ViewCaseModal: React.FC<ViewCaseModalProps> = ({ show, onHide, caseData }) => {
    const [audioShow, setAudioShow] = useState(false);

    const handlePlayAudio = () => {
        setAudioShow(!audioShow); 
    };

    const handleShowPdf = () => {
        if (caseData.pdfUrl) {
            window.open(caseData.pdfUrl, '_blank'); // Open PDF in a new tab
        }else{
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: "PDF URL is not available"
            });
        }
    };

    const handleReject = () => {
        // Add logic for reject action
    };

    const handleAccept = () => {
        // Add logic for accept action
    };

    const renderRow = (label: string, value: string | React.ReactNode) => (
        <Row className={css(styles.row)} key={label}>
            <Col xs={6}>
                <p className={css(styles.textLeft)}>{label}</p>
            </Col>
            <Col xs={6}>{value}</Col>
        </Row>
    );

    return (
        <Modal show={show} onHide={onHide} centered className={css(styles.modal)}>
            <Modal.Header className={classNames('pt-4, p-5',css(styles.modalHeader))}>
                <Row className={css(styles.headerRow)}>
                    <Col xs={6} className={css(styles.titleCol)}>
                        <Modal.Title className="font-weight-bold">{caseData.title}</Modal.Title>
                    </Col>
                    <Col xs={6} className={css(styles.closeCol)}>
                        <FontAwesomeIcon icon={faWindowClose} onClick={onHide} className={css(styles.closeIcon)} />
                    </Col>
                </Row>
                </Modal.Header>
                <Modal.Body className={classNames('ps-5',css(styles.modalBody))}>
                <If condition={audioShow}>
                    <AudioPlayerModal audioUrl={caseData.audioUrl}/>
                </If>
                {renderRow('Victim', caseData.victim)}
                {renderRow('Dependent', caseData.dependent)}
                {renderRow('Location', caseData.location)}
                {renderRow('Added Date', caseData.addedDate)}
                {renderRow('Reported Date', caseData.reportedDate)}
                {renderRow('Reported Officer', caseData.reportedOfficer)}
                {renderRow('Reported Office', caseData.reportedOffice)}
                {renderRow('Case Description', caseData.caseDescription)}
                <Row className={css(styles.row)}>
                    <Col xs={6}>
                        <Button variant="primary" onClick={handlePlayAudio} className="font-weight-bold">
                            Play Audio
                        </Button>
                    </Col>
                </Row>
                <Row className={css(styles.row)}>
                    <Col xs={6}>
                        <Button variant="secondary" onClick={handleShowPdf} className="font-weight-bold">
                            Show PDF
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={classNames('d-flex justify-content-between ps-5 pe-5 py-4', css(styles.modalFooter))}>
                <Button variant="secondary" onClick={onHide} className={css(styles.cancelButton)}>
                    Cancel
                </Button>
                <div className="ml-auto">
                    <Button variant="danger" onClick={handleReject} className="font-weight-bold">
                        Reject
                    </Button>
                    <span className={css(styles.buttonSpace)}></span>
                    <Button variant="success" onClick={handleAccept} className="font-weight-bold">
                        Accept
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modal: {
        color: 'black'
    },
    modalHeader: {
        width: '730px',
        backgroundColor: 'white',
        borderBottom: 'none',
    },
    modalBody: {
        width:'730px',
        backgroundColor: 'white',
        fontSize: '15px',
        lineHeight: '1.4px',
    },
    headerRow: {
        width: '100%',
    },

    titleCol: {
        textAlign: 'left',
    },

    closeCol: {
        textAlign: 'right',
    },
    modalFooter: {
        width:'730px',
        backgroundColor: 'white',
        borderTop: 'none',
    },
    buttonSpace: {
        marginLeft: '10px',
    },
    row: {
        marginBottom: '10px',
    },
    textLeft: {
        textAlign: 'left',
        fontWeight: 'bold'
    },
    cancelButton: {
        color: 'blue',
        borderColor: 'blue',
        backgroundColor: 'white',
        fontSize: '1rem',
        width: '120px'
    },
    closeIcon: {
        cursor: 'pointer',
        fontSize: '1.5rem',
        marginRight: '10px',
    },
});

export default ViewCaseModal;
