import { useCallback, useEffect, useState } from 'react';

import { Axios, AxiosResponse } from 'axios';

import getHttpClient from 'services/middleware/axios';

interface UseAxiosGetReturn {
  loading: boolean;
  data: object | [] | any;
  error: string | null | object;
  refetch: () => void;
}

function useAxiosGet(url: string): UseAxiosGetReturn {
  const axios: Axios = getHttpClient();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<object | []>({});
  const [error, setError] = useState<any>();

  const get = useCallback(async () => {
    setLoading(true);
    try {
      const response: AxiosResponse = await axios.get(url);
      if (response) {
        setData(response.data);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url]);

  const refetch = useCallback(async () => {
    await get();
  }, [url]);

  return { loading, error, data, refetch };
}

export default useAxiosGet;
