import cookies from 'js-cookie';

import { ACCESS_TOKEN, ID_TOKEN, CURRENT_USER } from 'constants/cookies';

function useCookie() {
  const setCookies = (accessToken: string, idToken: string): void => {
    cookies.set(ACCESS_TOKEN, accessToken, { secure: true, sameSite: 'strict' });
    cookies.set(ID_TOKEN, idToken, { secure: true, sameSite: 'strict' });
  };

  const setCookie = (key: string, value: string): void => {
    cookies.set(key, value, { secure: true, sameSite: 'strict' });
  };

  const removeCookies = () => {
    cookies.remove(ACCESS_TOKEN);
    cookies.remove(ID_TOKEN);
    cookies.remove(CURRENT_USER);
  };

  const getCookies = () => cookies.get();

  const getCookie = (name: string): string | undefined | null =>
    cookies.get(name);

  return { setCookies, setCookie, removeCookies, getCookies, getCookie };
}

export default useCookie;
