const ACCESS_TOKEN = 'accessToken';
const ID_TOKEN = 'idToken';
const LOGIN_STATE = 'loginState';
const REGISTERED = 'registered';
const CURRENT_USER = 'currentUser';

const LOGIN_STATES = {
  LOGIN : 'login',
  REGISTRATION : 'register',
  VERIFICATION : 'verification',
  FORGOT_PASSWORD : 'forgotPassword',
};

export { ACCESS_TOKEN, ID_TOKEN, LOGIN_STATE, LOGIN_STATES, REGISTERED, CURRENT_USER};
