import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { For } from "tsx-control-statements/components";

import useIncidentContext from "lib/hooks/useIncidentContext";
import InputFieldForList from "components/domesticTemplate/domesticTemplatePage1/inputFieldForList";

interface WitnessData {
  name: string;
  dob: string;
  address: string;
  cityStateZip: string;
  phone: string;
}

function DomesticWitnesses() {
  const { generatedResult } = useIncidentContext();
  const [witnessData, setWitnessData] = useState<WitnessData[]>([]);

  useEffect(() => {
    if (generatedResult && generatedResult["Witness"]) {
      const witnesses = generatedResult["Witness"] || [];
      const formattedWitnesses = witnesses.map((witness: any) => ({
        name: witness["name"] || "",
        dob: witness["birthday of witness"] || "",
        address: witness["address"] || "",
        cityStateZip: `${witness["City"] || ""}  ${witness["State"] || ""}  ${witness["zip"] || ""}`,
        phone: witness["Phone Number"] || "",
      }));
      setWitnessData([...formattedWitnesses]);
    }
  }, [generatedResult]);

  const handleInputChange = (index: number, field: string, value: string) => {
    const newWitnessData = [...witnessData];
    newWitnessData[index] = {
      ...newWitnessData[index],
      [field]: value,
    };
    setWitnessData(newWitnessData);
  };

  return (
    <div className="d-flex flex-column w-100 border-start border-end border-black border-3 p-0">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span>Witnesses</span>
      </div>
      <div className="d-flex flex-column border-start border-end border-black border-3">
        <For
          each="rowLine"
          of={witnessData}
          body={(rowLine, index) => (
            <div key={index} className="d-flex flex-row border-bottom border-black">
              <div className="col-3 border-end border-black">
                <InputFieldForList
                  inputFieldText={`Child/Witness (${index + 1}) Name (Last, First, M.I.)`}
                  defaultValue={rowLine.name}
                  userInputHandler={handleInputChange}
                  field={"name"}
                  index={index}
                />
              </div>
              <div className="col-1 border-end border-black">
                <InputFieldForList
                  inputFieldText="DOB:"
                  defaultValue={rowLine.dob}
                  userInputHandler={handleInputChange}
                  field={"dob"}
                  index={index}
                />
              </div>
              <div className="col-3 border-end border-black">
                <InputFieldForList
                  inputFieldText="Child/Witness Address (Street No., Name, Bldg./Apt)"
                  defaultValue={rowLine.address}
                  userInputHandler={handleInputChange}
                  field={"address"}
                  index={index}
                />
              </div>
              <div className="col-3 border-end border-black">
                <InputFieldForList
                  inputFieldText="City, State, Zip"
                  defaultValue={rowLine.cityStateZip}
                  userInputHandler={handleInputChange}
                  field={"cityStateZip"}
                  index={index}
                />
              </div>
              <div className="col-2 border-end border-black">
                <InputFieldForList
                  inputFieldText="Phone:"
                  defaultValue={rowLine.phone}
                  userInputHandler={handleInputChange}
                  field={"phone"}
                  index={index}
                />
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
});

export default DomesticWitnesses;
