import React from 'react';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Routes from 'constants/generalRoutes';

function ErrorBoundaryMessage() {
  return (
    <div className={classNames(css(styles.wrapper), 'flex-center flex column')}>
      <div>
        <span className={classNames(css(styles.oopsMessage), 'text-center fw-bold')}>Oops!</span>
        <div className={classNames(css(styles.errorMessage), 'text-center my-3')}>
          Something went wrong
        </div>
      </div>
      <div>
        <Button
          variant="warning"
          onClick={() => window.location.reload()}
          className="me-2">
          <FontAwesomeIcon
            icon={faRefresh}
            className="mx-2"
          />
          Refresh
        </Button>
        <Button href={Routes.Home}>
          <FontAwesomeIcon
            icon={faHome}
            className="mx-2 my-auto"
          />
          Go to Homepage
        </Button>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    minHeight: '100vh',
    maxHeight: '100%',
  },
  oopsMessage: {
    fontSize: '8rem',
  },
  errorMessage: {
    fontSize: '2rem',
  },
});

export default ErrorBoundaryMessage;
