export const genderOptions = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' }
];

export const roleOptions = [
  { value: 'User', label: 'User' },
  { value: 'Head', label: 'Head' },
  { value: 'Officer', label: 'Officer' },
  { value: 'Admin', label: 'Admin' }
];
