import React, { useEffect, useRef } from 'react'

interface props {
    isRecording: boolean
}

const AudioVisualizer = ({isRecording} : props) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    let audioContext = new (window.AudioContext || null)();
    let analyser = audioContext.createAnalyser();
    let dataArray = new Uint8Array(analyser.frequencyBinCount);
  
    useEffect(() => {
      if (isRecording === true) {
  
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          let source = audioContext.createMediaStreamSource(stream);
          source.connect(analyser);
          draw();
        });
      }
    }, [isRecording]);
  
    function draw() {
      if (!canvasRef.current) return;
  
      requestAnimationFrame(draw);
      analyser.getByteTimeDomainData(dataArray);
      const canvas = canvasRef.current;
      const height = canvas.height;
  
      const width = canvas.width;
      const context = canvas.getContext("2d");
      if (!context) return;
      context.clearRect(0, 0, width, height);
      context.beginPath();
  
      context.strokeStyle = "#556c82";
  
      var sliceWidth = (width * 1.0) / analyser.frequencyBinCount;
  
      var x = 0;
      for (var i = 0; i < analyser.frequencyBinCount; i++) {
        var v = dataArray[i] / 128.0;
        var y = (v * height) / 2;
        if (i === 0) {
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
        }
        x += sliceWidth;
      }
  
      context.lineTo(canvas.width, canvas.height / 2);
      context.stroke();
    }
  
    return (
      <div className="d-flex justify-content-center align-items-center">
        <canvas ref={canvasRef} height={40} width={100} />
      </div>
    );
}

export default AudioVisualizer