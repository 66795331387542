import React from 'react';

import AuthenticationView from "components/auth/AuthenticationView";
import ForgotPassword from "components/auth/ResetPassword/ForgotPassword";

const PasswordResetPage: React.FC = () => {
    return (
        <div>
            <AuthenticationView dynamicComponent={ForgotPassword} />
        </div>
    );
};

export default PasswordResetPage;

