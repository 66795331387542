import React from "react";

import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";

import DomesticPriorHistory from "components/domesticTemplate/domesticTemplatePage2/domesticPriorHistory";
import DomesticOfficerSection from "components/domesticTemplate/domesticTemplatePage2/domesticOfficerSection";
import DomesticSupportingDeposition from "components/domesticTemplate/domesticTemplatePage2/domesticSupportingDeposition";
import DomesticPageContacts from "components/domesticTemplate/domesticTemplatePage1/domesticPageContacts";

function DomesticTemplatePage2() {
  return (
    <div className={classNames(css(styles.a4Height), "mt-5 p-5 d-flex flex-column justify-content-between")}>
      <DomesticPriorHistory />
      <DomesticOfficerSection />
      <DomesticSupportingDeposition />
      <DomesticPageContacts />
    </div>
  );
}

const styles = StyleSheet.create({
  a4Height: {
    height: "1500px",
  },
});
export default DomesticTemplatePage2;
