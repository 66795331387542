// @ts-ignore
import React, { useRef, useState } from 'react';
import speechToTextUtils from './utilityTranscriber';

const SpeechToText = (
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
  transcribedData: React.RefObject<HTMLTextAreaElement>
) => {
  const [isRecording, setIsRecording] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const interimTranscribedData = useRef('');

  function handleDataReceived(data: any, isFinal: any) {
    // we will not use the state since due to the streaming it crashes the app
    if (isFinal) {
      // @ts-ignore
      transcribedData?.current = transcribedData?.current + data;
      // @ts-ignore
      interimTranscribedData?.current = '';
    } else {
      // @ts-ignore
      interimTranscribedData?.current = data;
    }
    // @ts-ignore
    textAreaRef?.current!.value = transcribedData?.current + ' ' + interimTranscribedData?.current || '';
  }

  function getTranscriptionConfig() {
    return {
      audio: {
        encoding: 'LINEAR16',
        sampleRateHertz: 32000,
        languageCode: selectedLanguage,
        model: 'latest_short'
      },
      interimResults: true,
    };
  }

  function onStart() {
    setIsRecording(true);

    speechToTextUtils.initRecording(
      getTranscriptionConfig(),
      handleDataReceived,
      (error: any) => {
        console.error('Error when transcribing', error);
        setIsRecording(false);
      },
    );
  }

  function onStop() {
    setIsRecording(false);
    speechToTextUtils.stopRecording();
  }

  return { isRecording, setIsRecording, onStart, onStop }
};

export default SpeechToText;
