import React from "react";

import {Col, Card } from 'react-bootstrap';

interface Props {
  value: string
}

function Column({value}: Props) {
  return (
    <Col>
      <Card.Text>
        {value}
      </Card.Text>
    </Col>
  );
}

export default Column;
