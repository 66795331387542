import { createContext, useContext } from 'react';

const INITIAL_VALUE: any = {};
const IncidentContext = createContext(INITIAL_VALUE);

function useIncidentContext() {
    return useContext(IncidentContext);
  }
  
  export default useIncidentContext;
  export { IncidentContext };