import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import { StyleSheet, css } from 'aphrodite';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import { For } from 'tsx-control-statements/components';

import NormalInput from 'components/templatePage1/normalInput';
import useIncidentContext from '../../lib/hooks/useIncidentContext';

function PersonDetails() {
  const [caseHumanDetails, setCaseHumanDetails] = useState([
    { text: 'Victim', value: { streetAddress: '', borough: '', telephone: '' }, line: 1 },
    { text: 'Perpetrator', value: { streetAddress: '', borough: '', telephone: '' }, line: 2 },
    { text: 'Other', value: { streetAddress: '', borough: '', telephone: '' }, line: 3 },
  ]);

  const { generatedResult } = useIncidentContext();
  useEffect(() => {
    if (generatedResult) {
      const updatedListofPerson = generatedResult.household.map((person: any, index: any) => {
        let text = '';
        if (person?.perpetratorOrVictim.toLowerCase() === 'victim') {
          text = 'Victim';
        } else if (person?.perpetratorOrVictim.toLowerCase() === 'perpetrator') {
          text = 'Perpetrator';
        } else {
          text = 'Other';
        }

        return {
          text: text,
          value: {
            streetAddress: person?.streetAddress =='NA' ? '': person?.streetAddress,
            borough: person?.borough =='NA' ? '': person?.borough,
            telephone: person?.telephone =='NA' ? '': person?.telephone,
          },
          line: index + 1,
        };
      });

      const updatedListofOther = setCaseHumanDetails(updatedListofPerson);
    }
  }, [generatedResult]);

  const inputHandler = (index: number, text: string, value: any) => {
    setCaseHumanDetails((prevList) => {
      const updatedList = [...prevList];
      if (text == 'line') {
        updatedList[index].line = value;
      }
      if (text == 'streetAddress') {
        updatedList[index].value.streetAddress = value;
      }
      if (text == 'borough') {
        updatedList[index].value.borough = value;
      }
      if (text == 'telephone') {
        updatedList[index].value.telephone = value;
      }
      return updatedList;
    });
  };

  return (
    <>
      <Row className={classNames(css(styles.normalFont), 'text-dark border-bottom border-1 border-dark')}>
        <Col className="col-8 h-100">
          <Row>
            <Col className="col-1 d-flex align-items-end">Line No.</Col>
            <Col className="col-2 d-flex align-items-end">Person</Col>
            <Col className="col-9 d-flex align-items-end">Street Address</Col>
          </Row>
        </Col>
        <Col className="col-4 h-100">
          <Row className="h-100">
            <Col className="col-3 d-flex align-items-end">Borough</Col>
            <Col className="col-9 d-flex align-items-end">Telephone No.</Col>
          </Row>
        </Col>
      </Row>

      <For
        each="rowLine"
        of={caseHumanDetails}
        body={(rowLine, index) => (
          <Row className="text-dark border-bottom border-1 border-dark pe-0">
            <Col className="col-8 pe-0">
              <div className="d-flex h-100 p-0">
                <Col className="col-1 border-end border-dark border-1 p-0">
                  <NormalInput userInputHandler={inputHandler} index={index} text="line" defaultValue={caseHumanDetails[index].line} />
                </Col>
                <Col className="col-2 border-end border-dark border-1">{rowLine.text}</Col>
                <Col className="col-9 border-end border-dark border-1 p-0">
                  <NormalInput userInputHandler={inputHandler} index={index} text="streetAddress" defaultValue={caseHumanDetails[index].value?.streetAddress} />
                </Col>
              </div>
            </Col>
            <Col className="col-4 p-0">
              <div className="d-flex h-100 p-0">
                <Col className="col-3 border-end border-dark border-1 p-0">
                  <NormalInput userInputHandler={inputHandler} index={index} text="borough" defaultValue={caseHumanDetails[index].value?.borough} />
                </Col>
                <Col className="col-9 p-0">
                  <NormalInput userInputHandler={inputHandler} index={index} text="telephone" defaultValue={caseHumanDetails[index].value?.telephone} />
                </Col>
              </div>
            </Col>
          </Row>
        )}
      />
    </>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: '0.9rem',
  },
});
export default PersonDetails;
