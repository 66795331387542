import React, { useEffect, useRef, useState } from "react";

import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import generatePDF, { Options, Resolution, Margin } from "react-to-pdf";

import { EditButtonState } from "constants/enums";
import { faArrowLeft, faFile, faEdit, faRedo, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GeneralRoutes from "constants/generalRoutes";
import Roles from "constants/roles";
import { useNavigate } from "react-router-dom";
import useIncidentContext from "../lib/hooks/useIncidentContext";
import classNames from "classnames";
import { StyleSheet, css } from "aphrodite";
import TemplatePage1 from "components/templatePage1/templatePage1";
import TemplatePage2 from "components/templatePage2/templatePage2";
import DomesticTemplatePage1 from "components/domesticTemplate/domesticTemplatePage1/domesticTemplatePage1";
import DomesticTemplatePage2 from "components/domesticTemplate/domesticTemplatePage2/domesticTemplatePage2";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";
import useCurrentUser from "hooks/useCurrentUser";
import useIncidentActions from 'hooks/api/useIncidentActions';
import Swal from "sweetalert2";

interface Props {
  data?: string[];
}
const templateElement = [
  {
    title: "Child Abuse",
    page1: <TemplatePage1 key={0} />,
    page2: <TemplatePage2 key={1} />,
  },
  {
    title: "Domestic",
    page1: <DomesticTemplatePage1 />,
    page2: <DomesticTemplatePage2 />,
  },
];
function ResultDisplay({ data }: Props) {
  const getTargetElement = () => document.getElementById("template-pdf-page-1");
  const secondPageRef = React.useRef(null);
  const [isContentReady, setIsContentReady] = useState(false);

  const navigate = useNavigate();
  const { generatedResult, setGeneratedResult, templateType, incidentText, setIncidentText, } = useIncidentContext();
  const { handleEditing } = useTemplateEditingContext();

  const [isTextAreaEditable, setIsTextAreaEditable] = useState(false);
  const [editButtonCaption, setEditButtonCaption] = useState(EditButtonState.Edit);
  const [editedResult, setEditedResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();
  const { saveIncident, clearIncidentAudioSaved } = useIncidentActions();

  const handleReEnter = () => {
    handleEditing(false);
    setGeneratedResult("");
    navigate("/" + Roles.Officer + GeneralRoutes.InsertCase);
  };

  const handleEdit = () => {
    if (editButtonCaption == EditButtonState.Edit) {
      handleEditing(true);
    } else {
      handleEditing(false);
    }

    setIsTextAreaEditable(!isTextAreaEditable);
    setEditButtonCaption(isTextAreaEditable ? EditButtonState.Edit : EditButtonState.Done);
  };

  const handleBackBtn = () => {
    handleEditing(false);
    setGeneratedResult("");
    navigate("/" + Roles.Officer + GeneralRoutes.SelectTemplate);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const incidentCase = {
      officer: currentUser?.copId,
      incident: incidentText,
      openAiResponse: generatedResult,
    };
    const response: any = await saveIncident(incidentCase);
    if(response){
      setIncidentText("");
      setGeneratedResult("");
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: "Incident Saved successfully",
      });
      navigate('/' + Roles.Officer + GeneralRoutes.InsertCase);
    }
    setIsLoading(false);
  };

  const currentTemplate = templateElement.find((template) => template.title === templateType);

  return (
    <Container fluid className="px-4 mx-5">
      <Row>
        <Col>
          <h3>AI Filled Form</h3>
        </Col>
        <Col className="d-flex justify-content-end my-auto">
          <Button size="sm" variant="secondary" onClick={handleReEnter} className="me-2">
            <FontAwesomeIcon icon={faRedo} className="me-2" />
            Re-enter
          </Button>
          <Button size="sm" className="me-2" variant="secondary" onClick={() => generatePDF(getTargetElement, { filename: "case.pdf" })}>
            <FontAwesomeIcon icon={faFile} className="me-2" />
            Download
          </Button>
          <Button size="sm" variant="secondary" onClick={handleEdit}>
            <FontAwesomeIcon icon={isTextAreaEditable ? faSave : faEdit} className="me-2" />

            {editButtonCaption}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className={classNames(css(styles.textFont, styles.textArea), "p-5")}>
            <div id="template-pdf-page-1">
              <div className={classNames(css(styles.TemplatePageWidth), "mb-5 pt-0 pb-5")}>{currentTemplate?.page1}</div>
              <div className={classNames(css(styles.TemplatePageWidth), "mt-5 pt-5")}>{currentTemplate?.page2}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Button size="lg" variant="secondary" onClick={handleBackBtn}>
            <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
            Back
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Choose>
            <When condition={!isLoading}>
              <Button size="lg" onClick={handleSave}>
                Save
                <FontAwesomeIcon icon={faSave} className="ms-2" />
              </Button>
            </When>
            <Otherwise>
              <Button size="lg" disabled>
                <Spinner animation="border" role="status"/>
              </Button>
            </Otherwise>
          </Choose>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  textArea: {
    border: "2px solid",
    height: "60vh",
    maxHeight: "60vh",
    overflowY: "auto",
    maxWidth: "90vw",
    width: "90vw",
  },
  TemplatePageWidth: {
    width: "100%",
  },
  textFont: {
    fontSize: "0.95rem",
  },
});

export default ResultDisplay;
