import {UserProfileRoutes} from "constants/generalRoutes";
import Roles from "constants/roles";

export interface UserSidebarItem {
    icon: string;
    menu: string;
    link: string;
}

const userAccountRoute = "/" + Roles.User + UserProfileRoutes.UserAccount;
const userSecurityRoute = "/" + Roles.User + UserProfileRoutes.UserSecurity;

export const UserSidebarItems: UserSidebarItem[] = [
    {
        icon: 'BsClipboard2Plus',
        menu: 'Account',
        link: userAccountRoute
    },
    {
        icon: 'RiFileHistoryLine',
        menu: 'Security',
        link: userSecurityRoute
    },
];

export const copyRights = `© ${new Date().getFullYear()}. COPNOTE All rights reserved`;
