import React, { useState } from "react";
import { SharedContext } from "lib/hooks/useTemplateEditingContext";

const TemplateEditingContext = ({ children }: any) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditing = (state: boolean) => {
    setIsEditing(state);
  };

  return <SharedContext.Provider value={{ isEditing, setIsEditing, handleEditing }}>{children}</SharedContext.Provider>;
};

export default TemplateEditingContext;
