import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";

import useIncidentContext from "lib/hooks/useIncidentContext";
import DomesticInputBox from "components/domesticTemplate/domesticTemplatePage1/domesticInputBox";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticVictimReview() {
  const contentEditableRef = useRef(null);
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [victimData, setVictimData] = useState({
    Upset: "",
    Nervous: "",
    Crying: "",
    Angry: "",
    Other: "",
    emotionalConditionDiscription: "",
    firstWordsToOfficers: "",
    suspectMadeFearful: false,
    suspectMadeFearfulDiscribe: "",
    weaponUsed: false,
    gunUsed: false,
    weaponUsedDiscribe: "",
    suspectThreats: false,
    suspectThreatsTo: { Victim: "No", "Child(ren)": "No", "Commit Suicide": "No", Pet: "No", Other: "No" },
    suspectThreatsDiscribe: "",
    injured: false,
    injuredDescribe: "",
    inPain: false,
    inPainDescribe: "",
    strangulation: false,
    strangulationType: "",
    visibleMarks: false,
    visibleMarksDescribe: "",
    accessToGuns: false,
    accessToGunsDiscribe: "",
  });
  useEffect(() => {
    if (generatedResult && generatedResult["victim interview"]) {
      const victimInterview = generatedResult["victim interview"] || "";
      setVictimData({
        Upset: victimInterview["emotional-condition-of-victim"].Upset,
        Nervous: victimInterview["emotional-condition-of-victim"].Nervous,
        Crying: victimInterview["emotional-condition-of-victim"].Crying,
        Angry: victimInterview["emotional-condition-of-victim"].Angry,
        Other: victimInterview["emotional-condition-of-victim"].Other,
        emotionalConditionDiscription: victimInterview["emotional-condition description"],
        firstWordsToOfficers: victimInterview["victim-first-words"],
        suspectMadeFearful: victimInterview["Did-suspect-make-victim-fearful"] == "Yes",
        suspectMadeFearfulDiscribe: victimInterview["suspect-make-victim-fearful Describe"],
        weaponUsed: victimInterview["Weapon-used"] == "Yes",
        gunUsed: victimInterview["gun used"],
        weaponUsedDiscribe: victimInterview["victimInterview"],
        suspectThreats: victimInterview["suspect Threats"] == "Yes",
        suspectThreatsTo: victimInterview["suspectThreats to"],
        suspectThreatsDiscribe: victimInterview["suspect threats describe"],
        injured: victimInterview["Injured?"] == "Yes",
        injuredDescribe: victimInterview["injured description"] === "NA" ? "" : victimInterview["injured description"],
        inPain: victimInterview["In pain?"] == "Yes",
        inPainDescribe: victimInterview["pain description"] === "NA" ? "" : victimInterview["pain description"],
        strangulation: victimInterview["strangulation"] == "Yes",
        strangulationType: victimInterview["strangulation type"],
        visibleMarks: victimInterview["visible-marks"] == "Yes",
        visibleMarksDescribe: victimInterview["Visible-marks-Description"] === "NA" ? "" : victimInterview["Visible-marks-Description"],
        accessToGuns: victimInterview["Access to guns"] == "Yes",
        accessToGunsDiscribe: victimInterview[" access to gun describe"] == "NA" ? "" : victimInterview[" access to gun describe"],
      });
    }
  }, [generatedResult]);

  type ThreatsToKey = "Victim" | "Child(ren)" | "Commit Suicide" | "Pet" | "Other";

  const handleThreatsToChange = (key: ThreatsToKey) => {
    setVictimData({
      ...victimData,
      suspectThreatsTo: {
        ...victimData.suspectThreatsTo,
        [key]: victimData.suspectThreatsTo[key] === "Yes" ? "No" : "Yes",
      },
    });
  };

  const handleInputChange = (field: any, value: any) => {
    setVictimData({
      ...victimData,
      [field]: value,
    });
  };
  const handleUserText = (input: any) => {
    setVictimData({
      ...victimData,
      ["firstWordsToOfficers"]: input,
    });
  };
  const handleInput = (e: any) => {
    handleUserText(e.currentTarget.innerHTML);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    insertTextAtCursor(text);
  };

  const insertTextAtCursor = (text: any) => {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }, [victimData.firstWordsToOfficers]);

  const handleCheckBox = (field: string) => {
    var value;
    if ("Upset" == field) {
      value = victimData.Upset;
    }
    if ("Nervous" == field) {
      value = victimData.Nervous;
    }
    if ("Crying" == field) {
      value = victimData.Crying;
    }
    if ("Angry" == field) {
      value = victimData.Angry;
    }
    if ("Other" == field) {
      value = victimData.Other;
    }
    var newValue = "";
    if (value == "Yes") {
      newValue = "No";
    } else {
      newValue = "Yes";
    }
    setVictimData({
      ...victimData,
      [field]: newValue,
    });
  };
  return (
    <div className="d-flex flex-column w-100 border-start border-bottom border-end border-black border-3 p-0">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span className="d-flex no-wrap">Victim Interview</span>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column w-100 border-start border-black border-3">
          <div className="d-flex flex-row border-bottom border-black">
            <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0 flex-fill")}>
              <div className="d-flex">Emotional condition of VICTIM?</div>
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Upset"
                  className="m-0"
                  checked={victimData.Upset == "Yes"}
                  onChange={(e) => handleCheckBox("Upset")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Nervous"
                  className="m-0"
                  checked={victimData.Nervous == "Yes"}
                  onChange={(e) => handleCheckBox("Nervous")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Crying"
                  className="m-0"
                  checked={victimData.Crying == "Yes"}
                  onChange={(e) => handleCheckBox("Crying")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Angry"
                  className="m-0"
                  checked={victimData.Angry == "Yes"}
                  onChange={(e) => handleCheckBox("Angry")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="Other"
                  className="m-0"
                  checked={victimData.Other == "Yes"}
                  onChange={(e) => handleCheckBox("Other")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex flex-fill">
                <DomesticInputBox
                  inputFieldText="emotionalConditionDiscription"
                  userInputHandler={handleInputChange}
                  defaultValue={victimData.emotionalConditionDiscription}
                />
              </div>
            </div>
          </div>
          <div className="border-bottom border-black">
            <div className={classNames(css(styles.normalFont), "d-flex flex-column")}>
              What were the first words that VICTIM said to the Responding Officers at the scene regarding the incident?
            </div>

            <div
              className={classNames(css(styles.normalFont, styles.textAreaDiv), "border-0")}
              contentEditable={isEditing}
              ref={contentEditableRef}
              onInput={handleInput}
              onPaste={handlePaste}
              dangerouslySetInnerHTML={{ __html: victimData.firstWordsToOfficers }}
            ></div>
          </div>
          <div className="d-flex flex-row border-bottom border-black">
            <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0 flex-fill")}>
              <div className="d-flex">Did suspect make victim fearful?</div>
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  className="m-0"
                  checked={victimData.suspectMadeFearful === true}
                  onChange={() => handleInputChange("suspectMadeFearful", true)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="No"
                  className="m-0"
                  checked={victimData.suspectMadeFearful === false}
                  onChange={() => handleInputChange("suspectMadeFearful", false)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">If yes, describe:</div>
              <div className="d-flex flex-fill">
                <DomesticInputBox
                  defaultValue={victimData.suspectMadeFearfulDiscribe}
                  userInputHandler={handleInputChange}
                  inputFieldText="suspectMadeFearfulDiscribe"
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row border-bottom border-black">
            <div className="d-flex flex-column col-7">
              <div
                className={classNames(
                  css(styles.normalFont),
                  "d-flex flex-row justify-content-start flex-wrap p-0 flex-fill border-bottom border-end border-black"
                )}
              >
                <div className="d-flex">Weapon Used?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={victimData.weaponUsed === true}
                    onChange={() => handleInputChange("weaponUsed", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No Gun"
                    className="m-0"
                    checked={victimData.weaponUsed === false}
                    onChange={() => handleInputChange("weaponUsed", false)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={victimData.gunUsed === true}
                    onChange={() => handleInputChange("gunUsed", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No other"
                    className="m-0"
                    checked={victimData.gunUsed === false}
                    onChange={() => handleInputChange("gunUsed", false)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">describe:</div>
                <div className="d-flex flex-fill">
                  <DomesticInputBox
                    defaultValue={victimData.weaponUsedDiscribe}
                    userInputHandler={handleInputChange}
                    inputFieldText="weaponUsedDiscribe"
                  />
                </div>
              </div>

              <div
                className={classNames(
                  css(styles.normalFont),
                  "d-flex flex-row justify-content-start flex-wrap p-0 flex-fill border-end border-black"
                )}
              >
                <div className="d-flex">Access to Guns?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={victimData.accessToGuns === true}
                    onChange={() => handleInputChange("accessToGuns", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={victimData.accessToGuns === false}
                    onChange={() => handleInputChange("accessToGuns", false)}
                    disabled={!isEditing}
                  />
                </div>

                <div className="d-flex pe-1">If yes, describe:</div>
                <div className="d-flex flex-fill">
                  <DomesticInputBox
                    defaultValue={victimData.accessToGunsDiscribe}
                    userInputHandler={handleInputChange}
                    inputFieldText="accessToGunsDiscribe"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row col-5">
              <div
                className={classNames(
                  css(styles.normalFont),
                  "d-flex flex-row justify-content-start flex-wrap p-0 flex-fill border-end border-black"
                )}
              >
                <div className="d-flex">Suspect Threats?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={victimData.suspectThreats === true}
                    onChange={() => handleInputChange("suspectThreats", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={victimData.suspectThreats === false}
                    onChange={() => handleInputChange("suspectThreats", false)}
                    disabled={!isEditing}
                  />
                </div>

                <div className="d-flex pe-1">If Yes, Threats to:</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Victim"
                    className="m-0"
                    checked={victimData.suspectThreatsTo["Victim"] == "Yes"}
                    onChange={() => handleThreatsToChange("Victim")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Child(ren)"
                    className="m-0"
                    checked={victimData.suspectThreatsTo["Child(ren)"] == "Yes"}
                    onChange={() => handleThreatsToChange("Child(ren)")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Pet"
                    className="m-0"
                    checked={victimData.suspectThreatsTo["Pet"] == "Yes"}
                    onChange={() => handleThreatsToChange("Pet")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Commit Suicide"
                    className="m-0"
                    checked={victimData.suspectThreatsTo["Commit Suicide"] == "Yes"}
                    onChange={() => handleThreatsToChange("Commit Suicide")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Other"
                    className="m-0"
                    checked={victimData.suspectThreatsTo["Other"] == "Yes"}
                    onChange={() => handleThreatsToChange("Other")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">Describe:</div>
                <div className="d-flex flex-fill">
                  <DomesticInputBox
                    defaultValue={victimData.suspectThreatsDiscribe}
                    userInputHandler={handleInputChange}
                    inputFieldText="suspectThreatsDiscribe"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row border-bottom border-black">
            <div className="d-flex flex-column col-6">
              <div
                className={classNames(
                  css(styles.normalFont),
                  "d-flex flex-row justify-content-start flex-wrap p-0 flex-fill border-bottom border-end border-black"
                )}
              >
                <div className="d-flex">Injured?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={victimData.injured == true}
                    onChange={() => handleInputChange("injured", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={victimData.injured == false}
                    onChange={() => handleInputChange("injured", false)}
                    disabled={!isEditing}
                  />
                </div>

                <div className="d-flex pe-1">If yes, describe:</div>
                <div className="d-flex flex-fill">
                  <DomesticInputBox defaultValue={victimData.injuredDescribe} userInputHandler={handleInputChange} inputFieldText="injuredDescribe" />
                </div>
              </div>

              <div
                className={classNames(
                  css(styles.normalFont),
                  "d-flex flex-row justify-content-start flex-wrap p-0 flex-fill border-end border-black"
                )}
              >
                <div className="d-flex">In Pain ?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={victimData.inPain == true}
                    onChange={() => handleInputChange("inPain", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={victimData.inPain == false}
                    onChange={() => handleInputChange("inPain", false)}
                    disabled={!isEditing}
                  />
                </div>

                <div className="d-flex pe-1">If yes, describe:</div>
                <div className="d-flex flex-fill">
                  <DomesticInputBox defaultValue={victimData.inPainDescribe} userInputHandler={handleInputChange} inputFieldText="inPainDescribe" />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row col-6">
              <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0 border-end border-black")}>
                <div className="d-flex">Strangulation? </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={victimData.strangulation == true}
                    onChange={() => handleInputChange("strangulation", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={victimData.strangulation == false}
                    onChange={() => handleInputChange("strangulation", false)}
                    disabled={!isEditing}
                  />
                </div>

                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Loss of Consciousness"
                    className="m-0"
                    checked={victimData.strangulationType == "Loss of Consciousness"}
                    onChange={() => handleInputChange("strangulationType", "Loss of Consciousness")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Urination/Defecation"
                    className="m-0"
                    checked={victimData.strangulationType == "Urination/Defecation"}
                    onChange={() => handleInputChange("strangulationType", "Urination/Defecation")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Red eyes/Petechia"
                    className="m-0"
                    checked={victimData.strangulationType == "Red eyes/Petechia"}
                    onChange={() => handleInputChange("strangulationType", "Red eyes/Petechia")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Sore Throat"
                    className="m-0"
                    checked={victimData.strangulationType == "Sore Throat"}
                    onChange={() => handleInputChange("strangulationType", "Sore Throat")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Breathing Changed"
                    className="m-0"
                    checked={victimData.strangulationType == "Breathing Changed"}
                    onChange={() => handleInputChange("strangulationType", "Breathing Changed")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Difficulty Swallowing"
                    className="m-0"
                    checked={victimData.strangulationType == "Difficulty Swallowing"}
                    onChange={() => handleInputChange("strangulationType", "Difficulty Swallowing")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">Visible Marks?</div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={victimData.visibleMarks == true}
                    onChange={() => handleInputChange("visibleMarks", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={victimData.visibleMarks == false}
                    onChange={() => handleInputChange("visibleMarks", false)}
                    disabled={!isEditing}
                  />
                </div>

                <div className="d-flex pe-1">If yes, describe:</div>
                <div className="d-flex flex-fill">
                  <DomesticInputBox
                    defaultValue={victimData.visibleMarksDescribe}
                    userInputHandler={handleInputChange}
                    inputFieldText="visibleMarksDescribe"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
  textAreaDiv: {
    width: "100%",
    height: "auto",
    minHeight: "4.2em",
    resize: "none",
    padding: "0.5em",
    overflowY: "auto",
    borderWidth: 0,
  },
});
export default DomesticVictimReview;
