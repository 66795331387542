import React, { useState, useRef } from 'react';
import { Button, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor';
import { css, StyleSheet } from 'aphrodite';
import useCurrentUser from 'hooks/useCurrentUser';
import UserAPI from './UserAPI';
import { useNavigate } from "react-router-dom";
import { If } from 'tsx-control-statements/components';

const MAX_FILE_SIZE_MB = 3;

interface Props {
    show: boolean;
    onHide: () => void;
    updateProfileImage: (imgSrc: string) => void;
}

function ImageUploadModal({ show, onHide, updateProfileImage }: Props) {
    const currentUser = useCurrentUser();
    const navigate = useNavigate();
    const [image, setImage] = useState<string | null>(null);
    const [scale, setScale] = useState<number>(1);
    const editorRef = useRef<AvatarEditor | null>(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
                setErrorMessage(`File size exceeds the limit of ${MAX_FILE_SIZE_MB} MB.`);
                setImage(null);
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result as string);
                setErrorMessage("");
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCropImage = async () => {
        try {
            setLoading(true);
            if (editorRef.current && image) {
                const canvas = editorRef.current.getImage();
                const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
                if (blob) {
                    const formData = new FormData();
                    formData.append('email', currentUser?.email);
                    formData.append('new_file', blob, 'profile_picture.png');
                    const updatedImageUrl = await UserAPI.updateProfilePicture(formData);
                    updateProfileImage(updatedImageUrl);
                    onHide();
                    navigate('.', { replace: true });
                }
            }
        } catch (error) {
            setErrorMessage('Failed to update profile picture');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal {...{ show, onHide }} aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-90w" className="overflow-x-auto" size="xl" centered>
            <Modal.Header closeButton>
                <div className="w-100 d-flex justify-content-between">
                    <Form.Control accept="image/*" className="w-50 d-flex align-self-center justify-content-center bg-secondary" type="file" size="sm" onChange={handleImageChange} />
                </div>
            </Modal.Header>
            <Modal.Body className={css(styles.modalBodyContainer)}>
                <If condition={errorMessage !== ""}>
                    <Alert variant="danger">{errorMessage}</Alert>
                </If>
                <If condition={image !== null}>
                    <AvatarEditor
                        ref={editorRef}
                        image={image as string}
                        width={200}
                        height={200}
                        border={50}
                        color={[255, 255, 255, 0.6]}
                        scale={scale}
                        borderRadius={100}
                    />
                </If>
            </Modal.Body>
            <Modal.Footer>
                <If condition={!loading}>
                    <Button className="rounded" onClick={handleCropImage} variant="outline-primary">
                        Upload Image
                    </Button>
                </If>
                <If condition={loading}>
                    <Spinner animation="border" size="sm" />
                </If>
            </Modal.Footer>

        </Modal>
    );
}

const styles = StyleSheet.create({
    modalBodyContainer: {
        maxHeight: '70vh',
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '3px',
    },
});

export default ImageUploadModal;
