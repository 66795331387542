import { useCallback, useState } from 'react';

import { Axios, AxiosResponse } from 'axios';

import getHttpClient from 'services/middleware/axios';

interface UseAxiosPostReturn {
  loading: boolean;
  error: string | null | object;
  post: (postBody: object) => void;
}

function useAxiosPost(url: string): UseAxiosPostReturn {
  const axios: Axios = getHttpClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | object | null>(null);

  const post = useCallback(
    async (postBody: object) => {
      setLoading(true);
      setError(null);
      try {
        const response: AxiosResponse = await axios.post(url, postBody);
        if (response) {
          return response.data;
        }
        setError(response);
      } catch (error: any) {
        setError(error?.response?.data?.detail);
      } finally {
        setLoading(false);
      }
    },
    [url]
  );
  return { loading, error, post };
}

export default useAxiosPost;
