const Templates = [
  {
    title: "Child Abuse",
    description: "Physical, Emotional, Sexual Abuse or Neglect, Violence",
    value: "CHILD_ABUSE",
    templateStructure: `
    Can you please provide the following information in the given format:
    Context:
  Incident Type: **Child Abuse**
  1. Read the incident text and identify the following:
  - Identify all children, adults, and alleged perpetrator(s) in the household. Try to find each person's first name, last name, middle initial, aliases, sex, birth date/age, ethnicity, relationship to the victim, and whether the person is a perpetrator or a victim. Also, identify the basis of suspicions.
  2. Create a JSON object from the identified information. Fill in all fields using only the information mentioned in the incident text. If you don't know, put 'NA' in those fields. 
  3. For the fields that require a True/False answer, state True or False. Choose the given options as stated in $option1|option2|option3|...|optionN$. 
  4. Under 'reasonOfSuspicion', state the reasons for suspicion. Include details about each child’s injuries, abuse or maltreatment, any evidence of prior injuries, abuse or maltreatment to the child or his siblings, and any evidence or suspicions of parental behavior contributing to the problem. 
  In 'gotAbusedOrMaltreated', if 'other' is is marked as True, provide more information about it in the ‘otherDetails’ section.
  5. Follow this JSON format:
  {date: , time: , precinct:{serialNo: , precinctName: }, 
  complaint: {isComplaint: True/False, complaintNo: , complaintDate: , complaintTime: , complaintLocation: , complaintType: ,  precinctName: },
  arrest: {isArrest: True/False, arrestDate: , arrestTime: , arrestLocation: , arrestType: , arrestPrecinct: , arrestNo: },
  household:[
  {firstName: , lastName: ,middleInitial: , aliases: , sex: , birthDate: {year: ,month: ,day: }, isChild: True/False, ethnicity: $blackOrAfricanAmerican|alaskanNative|asian|nativeAmerican|nativeHawaiianPacificIslander|white|other|unknown$, relationshipToVictim: $parent|parentalSubstitute|fosterParent|grandparent|relative|sibling|child|noRelationship|unknown$, suspicions: $gotAbused|gotMaltreated|none|unknown$, perpetratorOrVictim: $perpetrator|victim|other$, streetAddress: , borough:, telephone: ,
  gotAbusedOrMaltreated: { doaFatality: True/False, childsDrugAlcoholUse: True/False, educationalNeglect: True/False, fractures: True/False, poisoningNoxiousSubstances: True/False, emotionalNeglect: True/False, internalInjuries: True/False, chokingTwistingShaking: True/False, inadequateFoodClothingShelter: True/False, lacerationsBruisesWelts: True/False, lackOfMedicalCare: True/False, lackOfSupervision: True/False, burnsScalding: True/False, malnutritionFailureToThrive: True/False, abandonment: True/False, swellingDislocationSprains: True/False, inadequateGuardianship: True/False, parentsDrugAlcoholMisuse: True/False, excessiveCorporalPunishment: True/False, sexualAbuse: True/False, other: True/False, otherDetails: }}],
  reasonOfSuspicion: , suspiciousDate:{year: ,month: ,day: }, suspiciousTime: , 
  actionsTakenOrAboutToBeTake: {medicalExam: True/False, Photographs: True/False, XRay: True/False, Hospitalization: True/False, removalKeeping: True/False, returnedHome: True/False, medicalExam: True/False, notifiedDA: True/False},
  complainantInformation: {name: , address: , borough: , telephone: , relationshipToVictim: },
  InfoReportingPoliceOfficer: {rank: , lastName: , command: , taxRegistrationNo: , date: , time: },
  }
  6. Output only the above mentioned values
  Please note that the JSON object should be in the same format as the example provided above.
    `,
  },
  {
    title: "Domestic",
    description: "Domestic Violence Incident Report Template",
    value: "Domestic",

    templateStructure: `Read the given incident text carefully and identify the following information:
    More Important:{All field of the JSON formalt want to be filled-importantly,1. Use "" only when information is truly not available and undefined and Unkown and not answered,2. For ages, calculate based on incident date and DOB,3. For shared addresses between married couples, use incident address,4. Multiple emotional states can be selected simultaneously,5. Document all suspect statements in interview section,6. Mark offenses based on described criminal conduct,7. Default to "No" for historical questions unless explicitly mentioned,8. Require explanations for all "No" answers regarding notices/documents given,9. Format all dates as MM/DD/YYYY and times in 24-hour format (HH:MM),11. Use empty string "" for any missing city, state, or zip code information instead of NA or other placeholders}  
    The JSON format should follow the structure below: please note that importantly all field are required 
        {
           Incident:{agency: , ORI number:, Incident number: ,reportedDate:(Report date to officer about the incident) , reportedTime:(Report time to officer about the incident) , occuredDate:(incident occuring date not the reported date) , occuredTime:("Please provide the time when the domestic incident actually began. This should reflect when the incident started, based on the information provided in the narrative, not when the officer was notified or arrived at the scene. if it is can not find give as " " not give your suggestion) , address :{Street No:, Street Name:, Blding. No:, Apt No:,City:(city that incident happen), State:(state that incident happen,Using the address please retrieve the corresponding state of usa from a reliable online source.), Zip:(Zip code that incident happen , Using the address  please retrieve the corresponding ZIP code of usa from a reliable online source.)},incidentstatus:(choose from these "'Officer Initiated','Radio Run','Walk-in'and 'ICAD (NYC)' ") , complaint number:},
           Incident Narrative:{Briefly describe the circumstances of this incident:('Briefly describe the circumstances of this incident", create a clear, chronological narrative that includes,Required Elements:(Date and time,Officer information,Location,Reporting party,Initial observations,Victim/suspect details,Actions taken,Outcomes),Content Guidelines:(Include ages for minors,Document safety measures,Include relevant statements,Note final disposition)),DIR Repository checked:(Choose from 'yes' and 'No'),Order of Protection Registry checked:(Choose from 'yes' and 'No'),Order of Protection in effect:(Choose from 'yes' and 'No'),Refrain: ("If it orders the suspect to refrain from certain behaviors like harassment, threats, or violence."),Stay Away:("If it orders the suspect to stay away from certain locations or people, such as the victim's home, workplace, or school.")},
           Victim :{name :(Name of the victim (Last First  M.I.):(if these are NA give blank space) (Include Aliases)) ,address:{Street No:, Street Name:, Blding. No:, Apt No:,City:(city that incident happen), State:(state that incident happen, Using the address, please retrieve the corresponding state of "USA" from a reliable online source.), Zip:(Zip code that incident happen,Using the address ,please retrieve the corresponding ZIP code of "USA" from a reliable online source.)}, PhoneNumber: (private contact number of victim),safeContact: (A secure way to contact the victim that minimizes risk, phone number or an email address ),birthDate:(birth date of the victim),age:(calculate this using the reportedDate and the birthDate), gender:(gender choose only from these "'Female','Male','self-Identified'"),self-Identified:(if give gender field to 'self-Identified' give the relevant gender for victim),language:(language of victim only take from given text do not include your suggestion),
           ethnicity:(ethnicity select only from these "'White','Black','Asian','American Indian','other'",this field is required if it is not given in the text give as " "),race:(race select only from these"'Hispanic'and 'Non-Hispanic' 'Unknown'",this field is required),race Description:(if 'race' field is 'Unknown' give description about it,this field is required) } ,
           suspect :{name :(Name (Last First M.I.):(if these are NA give blank space) (Include Aliases)) ,address:this is address of suspect{Street No:, Street Name:, Blding. No:, Apt No:,City:(city that incident happen), State:(state that incident happen), Zip:(Zip code that incident happen)},birthDate: ,age:(calculate this using the reportedDate and the birthDate), gender:(gender choose only from these "'Female','Male','self-Identified'"),self-Identified:(if gender self_identified describe the gender), PhoneNumber: (personal contact of the suspect, used for direct contact not a landline),ethnicity:(ethnicity select only from these "'White','Black','Asian','American Indian','other'"),race:(race select only from these"'Hispanic'and 'Non Hispanic' 'Unknown'"),race Description:(if 'race' field is 'Unknown' give description about it),Language:(laguage of suspect), do-suspect-and-victim-live-together :Yes/No , Suspect-present:Yes/No, was-suspect injured:(Choose from "'Yes','No'"), injury description:if injured filed yesdescribe assign to this filed, Possible-drug-or-alcohol:Yes/No,Suspect-supervised:(suspect-supervised choose from "'Probation','Parole','Not Supervised','Status Unknown'"),Suspect-relationship-to-victim:(Suspect-relationship-to-victim choose from "'Married','Intimate Partner/Dating',' Formerly Married','Former Intimate Partner','Parent of Victim','Child of Victim','Relative','other'"),relativeDiscription:(if 'Suspect-relationship-to-victim' field is 'Relative' describe it otherwise " " ),otherDescription:(if 'Suspect-relationship-to-victim' field is 'other' describe it otherwise " "),Do the suspect and victim have a child in common:(Yes/No)},
           victim interview:{emotional-condition-of-victim:(emotional-condition-of-victim give the object {Upset:("Yes"or"No"),Nervous:("Yes"or"No"),Crying:("Yes"or"No"),Angry:("Yes"or"No"),Other:("Yes"or"No")}),emotional-condition description: (describe the emotional-condition-of-victim),victim-first-words:(What were the first words that VICTIM said to the Responding Officers at the scene regarding the incident?) ,Did-suspect-make-victim-fearful:(choose from Yes/No),suspect-make-victim-fearful Describe:(Did-suspect-make-victim-fearful.If 'Yes', describe it),suspect Threats:(  Suspect Threats?  Yes or No), suspectThreats to:(suspect Threats?, If Yes, Threats to give the object { Victim :("Yes"or"No"), Child(ren):("Yes"or"No"), Pet:("Yes"or"No"), Commit Suicide:("Yes"or"No"), Other :("Yes"or"No") ),suspect threats describe:(if choose other for 'suspectThreats to' field describe it ) , Weapon-used:(Weapon-used select from these "'Yes','No'"),weapon used description:(.If suspect used weapon,state what is the weapon otherwise give " "),gun used:( gun-used select from these "'Yes','No'"),Access to guns:(Yes/No,if 'Yes',describe), access to gun describe:(if 'access to gun' field 'Yes',describe it otherwise " "),Injured?:(select from these Yes/No),injured description:(Injured? Yes/No,if 'Yes',describe it otherwise " "),In pain? :(Yes/No,if 'Yes',describe),pain description:(In pain? Yes/No,if 'Yes',describe otherwise " "),
           strangulation:(Strangulation? choose 'yes' or 'No'),strangulation type:(if strangulation is there choose the relevant things from these  "'loss of conciousness','Urination/Defecation','Red eyse/Petechia','Sore throat','Breathing Changed','Diffulty Swallowing'"),visible-marks:(is there visible marks for strangulation choose from these " 'Yes' and 'No'"), Visible-marks-Description:(is there visible marks for Strangulation describe it otherwise " ")},
           Suspect Interview: {suspect-words-before-arrest:(what did the suspect say before arrest (provide a detailed account of the suspect's statements, including any relevant context or explanations)) ,suspect-words-after-arrest:( what did the suspect say after arrest (provide a detailed account of the suspect's statements, including any relevant context or explanations)) }, 
           710.30 completed:(choose from these "'Yes' and 'No'")},
           Witness:[{name :(Child or Witness Name (Last First M.I.)), birthday of witness:,address : Child or Witness Address Street No. Street Name  Bldg./Apt ,Phone Number:,City:(get city from the incident happen place if the witness location are same),State:(get state from the incident happen place if the witness location are same),zip:(get zip code from the incident happen place if the witness location are same)} identify the witness and give the array of this object {name :, birthday of witness:,address : ,Phone Number:,City:,State:,zip:} ],
           Evidence:{Evidence Present:Yes/No,Photos Taken:(choose from these "'Victim injury','Suspect Injury','Other'"),Other Evidence:(Choose from these "'Damaged Property','Videos','Electronic evidence','Other'"),Destruction of property:(Choose 'Yes/No'),description of Destruction of property:(IfDestruction of property is yes , Describe it and assign answer to here other wise fill it as " ") },
           Offence :{Committed:Yes/No,was suspect arrested:(Choose from 'yes' and 'No', not arrested explanation: if suspect not arrested explain why  },
           OffencesSuspect:{offence1 :,law1:(this is related to the offence done),offence2 :,law2:(this is related to the offence done)},
           Prior History:{priorIncidentsDescription:(Description of Victim's prior domestic incidents with this suspect,Describe Victim’s prior domestic incidents with this suspect (Last, Worst, First)),threatenedToKill:(Has suspect ever threatened to kill the victim or  victim's children choose from these (Yes/No)),strangled:(Has suspect ever Strangled or “choked” victim from these (Yes/No)),beatenWhilePregnant:( Has suspect ever beaten victim while victim was pregnant from these (Yes/No)),capableOfKilling:( Is suspect capable of killing victim or victim's children from these(Yes/No)), jealous:(Is suspect violently and constantly jealous of you from these (Yes/No)), increasedViolence:(Has the physical violence increased in frequency or severity over the past 6 months from these(Yes/No))},
           Supporting deposition:{country/city/town/village of incident happen:, incident description:(Please extract a detailed incident description from the following text. Include the sequence of events, the actions and statements of individuals involved, the context of the situation, and any relevant environmental details. Be specific about the relationships between people, the emotions conveyed, any injuries or damages, and the legal actions taken. Provide a clear, organized summary that avoids generalizations and captures all pertinent facts) ,Interpreter Used:(choose from ' Yes or No '),Interpreter Requested:(choose from ' Yes or No ') },
           officer filling:{reasonableCase:Is there reasonable cause to suspect a child may be the victim of abuse,neglect,maltreatment or endangerment:Yes/NO,DIR_Given:Was DIR given to the Victim at the scene:(Yes/NO), DIR_not_given_reason:Was DIR given to the Victim at the scene is no, then describe the reason otherwise " " ,Victim_right_given:Was Victim Rights Notice given to the Victim:(Yes/NO),Victim_Right_Notice_not _given_reason:Was Victim Rights Notice given to the Victim ,if 'No' describe it otherwise give " "}
        }    `,
  },
];

export default Templates;
