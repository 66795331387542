import React from "react";

import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";
import { Container, Navbar, NavDropdown } from "react-bootstrap";
import { If } from "tsx-control-statements/components";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Colors from "constants/colors";
import Roles from "constants/roles";
import { SidebarItems } from "constants/sidebarItems";
import useAuth from "hooks/useAuth";
import useCurrentUser from "hooks/useCurrentUser";
import { UserProfileRoutes } from "constants/generalRoutes";
import useScreenSize from "hooks/useScreenSize";
import useSharedContext from "lib/hooks/useSharedContext";

function TopNavBar() {
  const currentUser = useCurrentUser();
  const { logout } = useAuth();
  const { handleSidebar } = useSharedContext();

  const userProfileContent =
    currentUser?.profile_picture_url !== null ? (
      <img
        src={currentUser?.profile_picture_url}
        alt="user"
        className={classNames(css(styles.userProfile), "me-2 rounded-circle")}
      />
    ) : (
      <FontAwesomeIcon
        icon={faUser}
        className={classNames(css(styles.userProfile), "me-2 rounded-circle")}
      />
    );

  return (
    <Navbar className={css(styles.topNavbar)}>
      <Container fluid className="justify-content-between text-dark">
        <div>
          <FontAwesomeIcon
            onClick={handleSidebar}
            className={classNames(css(styles.hamburgerIcon), "ms-4 mt-2")}
            icon={faBars}
          />
        </div>
        <div>
          <div className="d-flex">
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={
                <div className="d-flex">
                  <div className="me-2 my-auto">
                    <h5 className="m-auto">{currentUser?.firstName}</h5>
                    <p
                      className={classNames(
                        css(styles.role),
                        "ml-auto mt-0 text-right"
                      )}
                    >
                      {currentUser?.role}
                    </p>
                  </div>
                  {userProfileContent}
                </div>
              }
              menuVariant="dark"
              className="my-auto"
            >
              <NavDropdown.Item href={SidebarItems[0].link}>
                Home
              </NavDropdown.Item>
              <NavDropdown.Item
                href={
                  "/" + `${Roles.User}` + `${UserProfileRoutes.UserAccount}`
                }
              >
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

const styles = StyleSheet.create({
  userProfile: {
    width: 48,
    height: 48,
  },
  role: {
    fontSize: "1rem",
  },
  topNavbar: {
    borderBottom: `1px solid ${Colors.Geyser}`,
  },
  hamburgerIcon: {
    cursor: "pointer",
    fontSize: "35px",
  },
});

export default TopNavBar;
