import React from "react";
import useAxiosPost from "services/middleware/useAxiosPost";

interface generateType {
  title: string;
  incident: string;
  templateStructure: string;
}

function useTemplate() {
  const { post } = useAxiosPost("/openAI/templateGenerate");
  const generate = async ({ title, incident, templateStructure }: generateType) => {
    const inputObj = {
      template: title,
      prompt: incident,
      templateStructure: templateStructure,
    };
    return await post(inputObj);
  };

  return { generate };
}

export default useTemplate;
